
import Dialog from "@feathers-client/mixins/Dialog";
import { mixins, Component, Prop, Watch, FindType, FindPopRawType } from "@feathers-client";
import { ProductCardInfo } from "./ProductCard.vue";
import _ from "lodash";

@Component
export default class ProductAdDetailCard extends mixins(Dialog) {
  @Prop()
  productInfo: ProductCardInfo;

  product: FindType<"shop/product/groups"> = null;

  filtedComments: FindPopRawType<["user"], "shop/product/comments">[] = [];

  get description() {
    return this.product?.desc || "暫無描述";
  }

  get buttonLabel() {
    return [
      { label: "全部", rating: 0 },
      { label: "5星", rating: 5 },
      { label: "4星", rating: 4 },
      { label: "3星", rating: 3 },
      { label: "2星", rating: 2 },
      { label: "1星", rating: 1 },
    ];
  }

  get detailRating() {
    return [24, 24, 2, 2, 2, 0];
  }

  get comments() {
    return this.productInfo.comments || [];
    // return [
    //   {
    //     name: "peterpan",
    //     rating: 4,
    //     date: "12 Jun 2020",
    //     content: "味道超乎想像的好!冰皮的口感很特別,入口即化,搭配濃郁的蓮蓉內餡,每一口都是享受。送禮自用兩相宜,推薦給大家!"
    //   },
    //   {
    //     name: "elainefun",
    //     rating: 4,
    //     date: "12 Jun 2020",
    //     content: "芒果口味的冰皮月餅驚艷到我了!冰涼的皮配上香甜的芒果餡,每一口都是夏日的味道。值得一試!"
    //   },
    //   {
    //     name: "happyha",
    //     rating: 4,
    //     date: "12 Jun 2020",
    //     content: "冰皮月餅的概念很有創意,實際吃起來也沒讓人失望。綠豆沙口味是我的最愛,不會太甜,回購率百分百。"
    //   },
    // ]
  }

  confirm() {
    this.modalResult(true);
  }

  get rating() {
    return this.productInfo.rating;
  }

  // Fetching real data

  productComments: FindType<"shop/product/comments">[] = [];
  productCommentSummary: FindType<"shop/product/comments/summary"> = null;

  @Watch("products")
  async fetchProductComments() {
    try {
      this.productComments = await this.$feathers.service("shop/product/comments").find({
        query: {
          sku: this.productInfo._id,
          $sort: {
            createdAt: -1,
          },
          $paginate: false,
        },
        paginate: false,
      });
    } catch (e) {
      console.error(e);
    }
    try {
      this.productCommentSummary = await this.$feathers.service("shop/product/comments/summary").create({
        sku: this.productInfo._id,
        shop: `${this.$shop._id}`,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async mounted() {
    this.fetchProductComments();
    this.product = await this.$feathers.service("shop/product/groups").get(this.productInfo.productGroup);
    this.filtedComments = this.comments;

    console.log(JSON.stringify(this.productInfo));
  }

  filterComment(rating: number) {
    if (rating === 0) {
      this.filtedComments = this.comments;
      return;
    }
    this.filtedComments = _.filter(this.comments, comment => comment.rating === rating);
  }
}
