import { Vue, Component, Watch } from "@feathers-client";
import type { PosHelper } from "../pos";

export interface PositionType {
  areaId: number;
  mapId: number;
  name: string;
  noiseRadius: number;
  pos: [number, number, number];
  rxAnchorId: number;
  rxAnchorSn: string;
  rxRssi: number;
  sn: string;
  sourceId: number;
  time: number;
  _id: string;
}

export interface FenceType {
  _id: string;
  eventTimeMs: number;
  eventStatus: number;
  entityId: number;
  entityCategory: string;
  entityName: string;
  pos: [number, number, number];
  tagId: number;
  mapId: number;
  mapName: string;
  fenceId: number;
  fenceName: string;
}

@Component
export class IPSManager extends Vue {
  connected = false;
  position: [number, number, number] = null;
  areaId: number = null;
  mapId: number = null;

  lastDirPos: [number, number, number] = null;
  direction: number = null;

  fenceId: number = null;
  fenceName: string = null;

  outOfActiveZone = false;
  outOfCheckoutZone = false;

  ipsEmulation = false;
  positionLogging = false;

  positions: {
    pos: [number, number];
    time: number;
    logged: boolean;
  }[] = [];

  get parent() {
    return this.$parent as PosHelper;
  }

  created() {
    (this.$feathers as any).on("connected", this.init);
    (this.$feathers as any).on("disconnected", this.disconnect);
    this.$feathers.service("shop/ips/position").on("created", this.onPosition);
    this.$feathers.service("shop/ips/fence").on("created", this.onFence);
  }

  disconnect() {
    this.connected = false;
  }

  @Watch("parent.cashier.ipsTag")
  async init() {
    this.connected = false;
    if (!this.$shop?._id) return;
    if (!this.parent?.cashier?._id || !this.parent?.cashier?.ipsTag) return;

    try {
      const ips = await this.$feathers.service("shop/ips/subscribe").create({
        tag: this.parent.cashier.ipsTag,
      });
      this.connected = true;

      if (this.$config.devMode && localStorage["ipsEmulation"]) {
        this.activateEmulation();
      }
    } catch (e) {
      console.warn(e);
    }
  }

  activateEmulation() {
    this.ipsEmulation = true;
    try {
      const json = JSON.parse(localStorage["ipsEmulation"]);
      this.position = json.position;
      this.areaId = json.areaId;
      this.mapId = json.mapId;
      this.sendEmulatePosition(json, false);
      if (json.fenceId) {
        this.sendEmulateFence(
          {
            fenceId: json.fenceId,
            fenceName: json.fenceName,
          },
          false,
        );
      }
    } catch (e) {}
  }

  deactivateEmulation() {
    if (this.ipsEmulation) {
      this.ipsEmulation = false;
      localStorage.removeItem("ipsEmulation");
    }
  }

  sendEmulatePosition(
    pos: {
      position?: [number, number, number];
      areaId?: number;
      mapId?: number;
    },
    save = true,
  ) {
    if (!this.ipsEmulation) return;
    const position = pos?.position || this.position || [0, 0, 0];
    const areaId = pos?.areaId || this.areaId;
    const mapId = pos?.mapId || this.mapId;

    this.onPosition({
      _id: this.parent.cashier.ipsTag,
      areaId: areaId,
      mapId: mapId,
      name: "emulated",
      noiseRadius: 0,
      pos: position,
      rxAnchorId: 0,
      rxAnchorSn: "emulated",
      rxRssi: 0,
      sn: "emulated",
      sourceId: 0,
      time: Date.now(),
    });

    if (save) {
      localStorage["ipsEmulation"] = JSON.stringify({
        position: position,
        areaId: areaId,
        mapId: mapId,
        fenceId: this.fenceId,
        fenceName: this.fenceName,
      });
    }
  }

  sendEmulateFence(
    fence: {
      fenceId: number;
      fenceName: string;
    },
    save = true,
  ) {
    if (!this.ipsEmulation) return;
    if (fence.fenceId) {
      this.onFence({
        _id: this.parent.cashier.ipsTag,
        eventTimeMs: Date.now(),
        eventStatus: 0,
        entityId: 0,
        entityCategory: "emulated",
        entityName: "emulated",
        pos: this.position,
        tagId: +this.parent.cashier.ipsTag,
        mapId: this.mapId,
        mapName: "emulated",
        fenceId: fence?.fenceId,
        fenceName: fence?.fenceName,
      });
    } else {
      this.onLeaveFence();
    }

    if (save) {
      localStorage["ipsEmulation"] = JSON.stringify({
        position: this.position,
        areaId: this.areaId,
        mapId: this.mapId,
        fenceId: fence?.fenceId,
        fenceName: fence?.fenceName,
      });
    }
  }

  async onPosition(position: PositionType) {
    if (`${position._id}` !== this.parent.cashier.ipsTag) return;
    if (this.ipsEmulation && position.sn !== "emulated") return;

    if (this.positionLogging) {
    }

    this.position = position.pos;
    this.areaId = position.areaId;
    if (!this.lastDirPos || distance(this.lastDirPos, position.pos) > 0.5) {
      if (this.lastDirPos) {
        this.direction =
          ((((Math.atan2(position.pos[1] - this.lastDirPos[1], -(position.pos[0] - this.lastDirPos[0])) * 180) /
            Math.PI) |
            0) +
            270) %
          360;
      }
      this.lastDirPos = position.pos;
    }
    if (this.mapId !== position.mapId) {
      this.positions = [];
      this.mapId = position.mapId;
      this.$emit("mapChanged", position.mapId);
    }

    const logged = await this.$pos.analyticsManager?.logPositionEvent();
    this.traceLogPosition(position.pos, logged);
  }

  traceLogPosition(position: [number, number, number] = this.position, logged = false) {
    if (!this.positionLogging) return;
    if (this.positions.length > 200) {
      this.positions.shift();
    }
    this.positions.push({
      pos: [position[0], position[1]],
      time: Date.now(),
      logged: logged,
    });
  }

  onFence(fence: FenceType) {
    if (`${fence._id}` !== this.parent.cashier.ipsTag) return;
    if (this.ipsEmulation && fence.entityName !== "emulated") return;

    if (this.fenceId !== fence.fenceId) {
      this.fenceId = fence.fenceId;
      this.fenceName = fence.fenceName;
      this.$emit("fenceChanged", fence.fenceId);

      this.outOfActiveZone = this.$shop.posInvalidFenceId && this.fenceId === this.$shop.posInvalidFenceId;
      this.outOfCheckoutZone = this.$shop.posCheckoutFenceId && this.fenceId !== this.$shop.posCheckoutFenceId;

      const ads = this.$pos.directZoneAds[fence.fenceId];
      if (ads?.length) {
        this.$pos.nextAd = ads[(Math.random() * ads.length) | 0];
      }
    }
  }

  onLeaveFence() {
    this.fenceId = null;
    this.fenceName = null;
    this.outOfCheckoutZone = !!this.$shop.posCheckoutFenceId;
  }
}

function distance(a: [number, number, number], b: [number, number, number]) {
  const x = a[0] - b[0];
  const y = a[1] - b[1];
  return Math.sqrt(x * x + y * y);
}
