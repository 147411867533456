import helper from "~/plugins/helper";
import { Context } from "@nuxt/types";

export default helper("schemaEditorAddons", (ctx: Context) => {
  if (process.server) return;

  ctx.app.$schemas.registerType("order", {
    format(self, v: number, item: any) {
      return item.order.orderId;
    },
  });

  ctx.app.$schemas.registerType("categoryList", {
    component: () => import("~/components/boxs/CategoryPicker.vue"),
    format: (ctx, value) => {
      return Array.isArray(value) ? value.map(it => it.tree?.map?.(jt => ctx.$td(jt.name))?.join("/")).join(",") : "";
    },
  });

  ctx.app.$schemas.registerType("categoryListTree", {
    format: (ctx, value) => {
      return Array.isArray(value) ? value.map(jt => ctx.$td(jt.name))?.join("/") : "";
    },
  });

  ctx.app.$schemas.registerType("currencyNum", {
    // @ts-ignore
    component: () => import("~/components/boxs/SingleCurrencyEditor.vue"),
    format: "currency",
  });

  ctx.app.$schemas.registerType("currencyNumRevert", {
    // @ts-ignore
    component: () => import("~/components/boxs/SingleCurrencyEditor.vue"),
    props: {
      revert: true,
    },
  });

  ctx.app.$schemas.registerType("paymentOption", {
    // @ts-ignore
    component: () => import("~/components/boxs/PaymentOptionEditor.vue"),
  });

  ctx.app.$schemas.registerType("paymentSecret", {
    // @ts-ignore
    component: () => import("~/components/boxs/PaymentSecretEditor.vue"),
  });

  ctx.app.$schemas.registerType("specList", {
    // @ts-ignore
    component: () => import("~/components/boxs/SpecEditor.vue"),
    // @ts-ignore
    searchComponent: () => import("~/components/boxs/SpecSearch.vue"),
  });

  ctx.app.$schemas.registerType("contactFieldList", {
    // @ts-ignore
    component: () => import("~/components/boxs/ContactFieldEditor.vue"),
  });

  ctx.app.$schemas.registerType("priceLineName", {
    // @ts-ignore
    component: () => import("~/components/boxs/PriceLineName.vue"),
  });

  ctx.app.$schemas.registerType("paymentInstruction", {
    // @ts-ignore
    component: () => import("~/components/boxs/PaymentInstructionEditor.vue"),
  });

  ctx.app.$schemas.registerType("articleType", {
    // @ts-ignore
    component: () => import("~/components/boxs/ArticleTypePicker.vue"),
  });

  ctx.app.$schemas.registerType("colorPicker", {
    // @ts-ignore
    component: () => import("~/dep/site-editor/ColorPicker.vue"),
  });

  ctx.app.$schemas.registerType("address", {
    // @ts-ignore
    component: () => import("~/components/boxs/AddressEditor.vue"),
    props: {
      addressType: "from",
    },
  });

  ctx.app.$schemas.registerHook("initImport", async (...args) => {
    (await import("./schemaImportAddons")).default(ctx);
  });

  ctx.app.$schemas.registerType("shopTable", {});
});
