var render, staticRenderFns
import script from "./SimpleList.vue?vue&type=script&lang=ts"
export * from "./SimpleList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kelson/Desktop/projects/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7be9d90b')) {
      api.createRecord('7be9d90b', component.options)
    } else {
      api.reload('7be9d90b', component.options)
    }
    
  }
}
component.options.__file = "components/SimpleList.vue"
export default component.exports