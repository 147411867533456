import { render, staticRenderFns } from "./DetailsViewer.vue?vue&type=template&id=62b9a999"
import script from "./DetailsViewer.vue?vue&type=script&lang=ts"
export * from "./DetailsViewer.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VBtn,VCard})


/* hot reload */
if (module.hot) {
  var api = require("/Users/kelson/Desktop/projects/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62b9a999')) {
      api.createRecord('62b9a999', component.options)
    } else {
      api.reload('62b9a999', component.options)
    }
    module.hot.accept("./DetailsViewer.vue?vue&type=template&id=62b9a999", function () {
      api.rerender('62b9a999', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/erp/dialogs/DetailsViewer.vue"
export default component.exports