import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _465ac3db = () => interopDefault(import('../pages/cashboxLogs.vue' /* webpackChunkName: "pages/cashboxLogs" */))
const _513311b1 = () => interopDefault(import('../pages/charts.vue' /* webpackChunkName: "pages/charts" */))
const _7f24cd53 = () => interopDefault(import('../pages/comments/index.vue' /* webpackChunkName: "pages/comments/index" */))
const _4646f7ac = () => interopDefault(import('../pages/companyUsers/index.vue' /* webpackChunkName: "pages/companyUsers/index" */))
const _11615b4a = () => interopDefault(import('../pages/couponTemplates/index.vue' /* webpackChunkName: "pages/couponTemplates/index" */))
const _61848f68 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _4f2049ae = () => interopDefault(import('../pages/discounts/index.vue' /* webpackChunkName: "pages/discounts/index" */))
const _6b58e400 = () => interopDefault(import('../pages/ips.vue' /* webpackChunkName: "pages/ips" */))
const _5df9a480 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _70033f34 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _3b88b328 = () => interopDefault(import('../pages/newsSubscription.vue' /* webpackChunkName: "pages/newsSubscription" */))
const _4255cbf8 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _b7cce968 = () => interopDefault(import('../pages/payments/index.vue' /* webpackChunkName: "pages/payments/index" */))
const _72bdc6aa = () => interopDefault(import('../pages/pos/index.vue' /* webpackChunkName: "pages/pos/index" */))
const _7b386eb4 = () => interopDefault(import('../pages/posScreen.vue' /* webpackChunkName: "pages/posScreen" */))
const _3599967a = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _59fc0fc0 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _4b18a8e2 = () => interopDefault(import('../pages/promotions/index.vue' /* webpackChunkName: "pages/promotions/index" */))
const _151b4f42 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _650efcc2 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _63e046c8 = () => interopDefault(import('../pages/shippings/index.vue' /* webpackChunkName: "pages/shippings/index" */))
const _06d0c6ee = () => interopDefault(import('../pages/shopUsers/index.vue' /* webpackChunkName: "pages/shopUsers/index" */))
const _dbc23322 = () => interopDefault(import('../pages/specs/index.vue' /* webpackChunkName: "pages/specs/index" */))
const _5df5c5e0 = () => interopDefault(import('../pages/switch.vue' /* webpackChunkName: "pages/switch" */))
const _04432dda = () => interopDefault(import('../pages/domoreTest/label.vue' /* webpackChunkName: "pages/domoreTest/label" */))
const _45d96979 = () => interopDefault(import('../pages/domoreTest/printer.vue' /* webpackChunkName: "pages/domoreTest/printer" */))
const _61d7bfc3 = () => interopDefault(import('../pages/domoreTest/test.vue' /* webpackChunkName: "pages/domoreTest/test" */))
const _67f94f2e = () => interopDefault(import('../pages/domoreTest/weight.vue' /* webpackChunkName: "pages/domoreTest/weight" */))
const _08d3f664 = () => interopDefault(import('../pages/erp/purchasingOrders/index.vue' /* webpackChunkName: "pages/erp/purchasingOrders/index" */))
const _0f2d90ee = () => interopDefault(import('../pages/erp/wholesaleOrders/index.vue' /* webpackChunkName: "pages/erp/wholesaleOrders/index" */))
const _4f4c25e0 = () => interopDefault(import('../pages/eshop/articles/index.vue' /* webpackChunkName: "pages/eshop/articles/index" */))
const _5e93462a = () => interopDefault(import('../pages/eshop/banners/index.vue' /* webpackChunkName: "pages/eshop/banners/index" */))
const _7242f7df = () => interopDefault(import('../pages/eshop/brands/index.vue' /* webpackChunkName: "pages/eshop/brands/index" */))
const _39658fa2 = () => interopDefault(import('../pages/eshop/categories/index.vue' /* webpackChunkName: "pages/eshop/categories/index" */))
const _833b7166 = () => interopDefault(import('../pages/eshop/contactField/index.vue' /* webpackChunkName: "pages/eshop/contactField/index" */))
const _1795c827 = () => interopDefault(import('../pages/eshop/contactForm/index.vue' /* webpackChunkName: "pages/eshop/contactForm/index" */))
const _85c08a78 = () => interopDefault(import('../pages/eshop/ecshopArticles/index.vue' /* webpackChunkName: "pages/eshop/ecshopArticles/index" */))
const _74a918e7 = () => interopDefault(import('../pages/eshop/pages/index.vue' /* webpackChunkName: "pages/eshop/pages/index" */))
const _6472fabb = () => interopDefault(import('../pages/eshop/relateds/index.vue' /* webpackChunkName: "pages/eshop/relateds/index" */))
const _b3f5a114 = () => interopDefault(import('../pages/eshop/settings/index.vue' /* webpackChunkName: "pages/eshop/settings/index" */))
const _5c5e5f8a = () => interopDefault(import('../pages/eshop/specs/index.vue' /* webpackChunkName: "pages/eshop/specs/index" */))
const _1e1ac760 = () => interopDefault(import('../pages/eshop/storeLocations/index.vue' /* webpackChunkName: "pages/eshop/storeLocations/index" */))
const _098f1a3d = () => interopDefault(import('../pages/eshop/storeTags/index.vue' /* webpackChunkName: "pages/eshop/storeTags/index" */))
const _127067ec = () => interopDefault(import('../pages/eshop/tags/index.vue' /* webpackChunkName: "pages/eshop/tags/index" */))
const _a83921a6 = () => interopDefault(import('../pages/eshop/warranties/index.vue' /* webpackChunkName: "pages/eshop/warranties/index" */))
const _29d1bbfe = () => interopDefault(import('../pages/extras/receipts/index.vue' /* webpackChunkName: "pages/extras/receipts/index" */))
const _221e0740 = () => interopDefault(import('../pages/login/reset/index.vue' /* webpackChunkName: "pages/login/reset/index" */))
const _7a695cb9 = () => interopDefault(import('../pages/logs/actionLogs.vue' /* webpackChunkName: "pages/logs/actionLogs" */))
const _54c3dba6 = () => interopDefault(import('../pages/logs/adLogs.vue' /* webpackChunkName: "pages/logs/adLogs" */))
const _4f28ad62 = () => interopDefault(import('../pages/logs/orderPositionLogs.vue' /* webpackChunkName: "pages/logs/orderPositionLogs" */))
const _289b239e = () => interopDefault(import('../pages/pos/cashBox.vue' /* webpackChunkName: "pages/pos/cashBox" */))
const _166999b2 = () => interopDefault(import('../pages/pos/openClose.vue' /* webpackChunkName: "pages/pos/openClose" */))
const _8f48f1f2 = () => interopDefault(import('../pages/pos/punch.vue' /* webpackChunkName: "pages/pos/punch" */))
const _ceffdb46 = () => interopDefault(import('../pages/pos/settings/index.vue' /* webpackChunkName: "pages/pos/settings/index" */))
const _1945ed15 = () => interopDefault(import('../pages/products/skus.vue' /* webpackChunkName: "pages/products/skus" */))
const _c93a5f00 = () => interopDefault(import('../pages/profile/changeInfo.vue' /* webpackChunkName: "pages/profile/changeInfo" */))
const _1b65baed = () => interopDefault(import('../pages/profile/changePassword.vue' /* webpackChunkName: "pages/profile/changePassword" */))
const _5048d2e7 = () => interopDefault(import('../pages/profile/manage.vue' /* webpackChunkName: "pages/profile/manage" */))
const _26d3c4b9 = () => interopDefault(import('../pages/register/verify.vue' /* webpackChunkName: "pages/register/verify" */))
const _31f6a9fa = () => interopDefault(import('../pages/report/driver/index.vue' /* webpackChunkName: "pages/report/driver/index" */))
const _728ceccb = () => interopDefault(import('../pages/report/driverReport.vue' /* webpackChunkName: "pages/report/driverReport" */))
const _6252f2b8 = () => interopDefault(import('../pages/settings/access.vue' /* webpackChunkName: "pages/settings/access" */))
const _1393aa8c = () => interopDefault(import('../pages/settings/email.vue' /* webpackChunkName: "pages/settings/email" */))
const _340647ae = () => interopDefault(import('../pages/settings/erpid.vue' /* webpackChunkName: "pages/settings/erpid" */))
const _aa00ba42 = () => interopDefault(import('../pages/settings/integrations.vue' /* webpackChunkName: "pages/settings/integrations" */))
const _92c0a768 = () => interopDefault(import('../pages/settings/inventory.vue' /* webpackChunkName: "pages/settings/inventory" */))
const _9ee7a468 = () => interopDefault(import('../pages/settings/invoiceTemplates.vue' /* webpackChunkName: "pages/settings/invoiceTemplates" */))
const _2ad9bcd0 = () => interopDefault(import('../pages/settings/newsSubscription.vue' /* webpackChunkName: "pages/settings/newsSubscription" */))
const _1f59d414 = () => interopDefault(import('../pages/settings/payment.vue' /* webpackChunkName: "pages/settings/payment" */))
const _6eb9e0ee = () => interopDefault(import('../pages/settings/pointTypes.vue' /* webpackChunkName: "pages/settings/pointTypes" */))
const _1698a978 = () => interopDefault(import('../pages/settings/pos.vue' /* webpackChunkName: "pages/settings/pos" */))
const _05b07fd6 = () => interopDefault(import('../pages/settings/posBanners/index.vue' /* webpackChunkName: "pages/settings/posBanners/index" */))
const _ea26e6a6 = () => interopDefault(import('../pages/settings/shippingCars.vue' /* webpackChunkName: "pages/settings/shippingCars" */))
const _2fb518c0 = () => interopDefault(import('../pages/settings/shippingContainers.vue' /* webpackChunkName: "pages/settings/shippingContainers" */))
const _6101b314 = () => interopDefault(import('../pages/settings/shippingMethods.vue' /* webpackChunkName: "pages/settings/shippingMethods" */))
const _3fec44f8 = () => interopDefault(import('../pages/settings/shippingProviders.vue' /* webpackChunkName: "pages/settings/shippingProviders" */))
const _296b5cae = () => interopDefault(import('../pages/settings/shippingZones.vue' /* webpackChunkName: "pages/settings/shippingZones" */))
const _63c79d36 = () => interopDefault(import('../pages/settings/shop.vue' /* webpackChunkName: "pages/settings/shop" */))
const _7f13ecf6 = () => interopDefault(import('../pages/settings/shopRankGroups.vue' /* webpackChunkName: "pages/settings/shopRankGroups" */))
const _181bd8e1 = () => interopDefault(import('../pages/settings/shopRanks.vue' /* webpackChunkName: "pages/settings/shopRanks" */))
const _e006a86e = () => interopDefault(import('../pages/settings/sms.vue' /* webpackChunkName: "pages/settings/sms" */))
const _b89687a6 = () => interopDefault(import('../pages/settings/social.vue' /* webpackChunkName: "pages/settings/social" */))
const _5d21bf26 = () => interopDefault(import('../pages/settings/socialMediaIntegrations.vue' /* webpackChunkName: "pages/settings/socialMediaIntegrations" */))
const _5213e94b = () => interopDefault(import('../pages/settings/turncloud/index.vue' /* webpackChunkName: "pages/settings/turncloud/index" */))
const _a63d4858 = () => interopDefault(import('../pages/settings/ui.vue' /* webpackChunkName: "pages/settings/ui" */))
const _5554718e = () => interopDefault(import('../pages/settings/verify.vue' /* webpackChunkName: "pages/settings/verify" */))
const _2520fa64 = () => interopDefault(import('../pages/shippings/driver.vue' /* webpackChunkName: "pages/shippings/driver" */))
const _47543b86 = () => interopDefault(import('../pages/shippings/schedule.vue' /* webpackChunkName: "pages/shippings/schedule" */))
const _5c3ed11a = () => interopDefault(import('../pages/warehouses/inventories/index.vue' /* webpackChunkName: "pages/warehouses/inventories/index" */))
const _b9bb1512 = () => interopDefault(import('../pages/warehouses/inventoryActions/index.vue' /* webpackChunkName: "pages/warehouses/inventoryActions/index" */))
const _46a3fccb = () => interopDefault(import('../pages/warehouses/inventoryLogs/index.vue' /* webpackChunkName: "pages/warehouses/inventoryLogs/index" */))
const _319a0022 = () => interopDefault(import('../pages/erp/wholesaleOrders/newOrder.vue' /* webpackChunkName: "pages/erp/wholesaleOrders/newOrder" */))
const _1582914c = () => interopDefault(import('../pages/erp/wholesaleOrders/quoSuccess.vue' /* webpackChunkName: "pages/erp/wholesaleOrders/quoSuccess" */))
const _5ed46637 = () => interopDefault(import('../pages/eshop/contactField/values.vue' /* webpackChunkName: "pages/eshop/contactField/values" */))
const _8d9209ee = () => interopDefault(import('../pages/eshop/specs/values.vue' /* webpackChunkName: "pages/eshop/specs/values" */))
const _6a3f1586 = () => interopDefault(import('../pages/login/reset/reset.vue' /* webpackChunkName: "pages/login/reset/reset" */))
const _8bafe70a = () => interopDefault(import('../pages/login/reset/verify.vue' /* webpackChunkName: "pages/login/reset/verify" */))
const _0bf7b2ba = () => interopDefault(import('../pages/settings/xero/callback.vue' /* webpackChunkName: "pages/settings/xero/callback" */))
const _e91477ac = () => interopDefault(import('../pages/warehouses/inventories/lots.vue' /* webpackChunkName: "pages/warehouses/inventories/lots" */))
const _20d44c24 = () => interopDefault(import('../pages/warehouses/inventories/summary.vue' /* webpackChunkName: "pages/warehouses/inventories/summary" */))
const _8ff36352 = () => interopDefault(import('../pages/eshop/settings/dialogs/PageSettingInfoDialog.vue' /* webpackChunkName: "pages/eshop/settings/dialogs/PageSettingInfoDialog" */))
const _1d959561 = () => interopDefault(import('../pages/eshop/settings/pages/layout.vue' /* webpackChunkName: "pages/eshop/settings/pages/layout" */))
const _463b0b69 = () => interopDefault(import('../pages/erp/purchasingOrders/edit/_id.vue' /* webpackChunkName: "pages/erp/purchasingOrders/edit/_id" */))
const _36f68b49 = () => interopDefault(import('../pages/erp/wholesaleOrders/edit/_id.vue' /* webpackChunkName: "pages/erp/wholesaleOrders/edit/_id" */))
const _59ad063c = () => interopDefault(import('../pages/erp/wholesaleOrders/monthlyStmt/_id.vue' /* webpackChunkName: "pages/erp/wholesaleOrders/monthlyStmt/_id" */))
const _0fa7b832 = () => interopDefault(import('../pages/eshop/articles/edit/_id.vue' /* webpackChunkName: "pages/eshop/articles/edit/_id" */))
const _785806e6 = () => interopDefault(import('../pages/eshop/banners/edit/_id.vue' /* webpackChunkName: "pages/eshop/banners/edit/_id" */))
const _a2e61a90 = () => interopDefault(import('../pages/eshop/brands/edit/_id.vue' /* webpackChunkName: "pages/eshop/brands/edit/_id" */))
const _467f1068 = () => interopDefault(import('../pages/eshop/categories/edit/_id.vue' /* webpackChunkName: "pages/eshop/categories/edit/_id" */))
const _7ec909b3 = () => interopDefault(import('../pages/eshop/ecshopArticles/edit/_id.vue' /* webpackChunkName: "pages/eshop/ecshopArticles/edit/_id" */))
const _4d2a56a0 = () => interopDefault(import('../pages/eshop/pages/edit/_id.vue' /* webpackChunkName: "pages/eshop/pages/edit/_id" */))
const _4fa9b25c = () => interopDefault(import('../pages/eshop/relateds/edit/_id.vue' /* webpackChunkName: "pages/eshop/relateds/edit/_id" */))
const _7d65c639 = () => interopDefault(import('../pages/extras/receipts/edit/_id.vue' /* webpackChunkName: "pages/extras/receipts/edit/_id" */))
const _69d88e78 = () => interopDefault(import('../pages/comments/edit/_id.vue' /* webpackChunkName: "pages/comments/edit/_id" */))
const _7fe0536d = () => interopDefault(import('../pages/couponTemplates/edit/_id.vue' /* webpackChunkName: "pages/couponTemplates/edit/_id" */))
const _1f9a59a4 = () => interopDefault(import('../pages/discounts/edit/_id.vue' /* webpackChunkName: "pages/discounts/edit/_id" */))
const _30406273 = () => interopDefault(import('../pages/orders/edit/_id.vue' /* webpackChunkName: "pages/orders/edit/_id" */))
const _314adb54 = () => interopDefault(import('../pages/products/edit/_id.vue' /* webpackChunkName: "pages/products/edit/_id" */))
const _6a0b6108 = () => interopDefault(import('../pages/promotions/edit/_id.vue' /* webpackChunkName: "pages/promotions/edit/_id" */))
const _d1890e4a = () => interopDefault(import('../pages/shippings/edit/_id.vue' /* webpackChunkName: "pages/shippings/edit/_id" */))
const _5e9315ce = () => interopDefault(import('../pages/shopUsers/edit/_id.vue' /* webpackChunkName: "pages/shopUsers/edit/_id" */))
const _6892f7ac = () => interopDefault(import('../pages/invoice/_id.vue' /* webpackChunkName: "pages/invoice/_id" */))
const _620579e1 = () => interopDefault(import('../pages/o/_id.js' /* webpackChunkName: "pages/o/_id" */))
const _6c32b280 = () => interopDefault(import('../pages/p/_id.js' /* webpackChunkName: "pages/p/_id" */))
const _5a61c3b3 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))
const _0f8206b4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/:shop([a-f\\d]{24})/cashboxLogs",
    component: _465ac3db,
    name: "shop-cashboxLogs"
  }, {
    path: "/:shop([a-f\\d]{24})/charts",
    component: _513311b1,
    name: "shop-charts"
  }, {
    path: "/:shop([a-f\\d]{24})/comments",
    component: _7f24cd53,
    name: "shop-comments"
  }, {
    path: "/:shop([a-f\\d]{24})/companyUsers",
    component: _4646f7ac,
    name: "shop-companyUsers"
  }, {
    path: "/:shop([a-f\\d]{24})/couponTemplates",
    component: _11615b4a,
    name: "shop-couponTemplates"
  }, {
    path: "/:shop([a-f\\d]{24})/dashboard",
    component: _61848f68,
    name: "shop-dashboard"
  }, {
    path: "/:shop([a-f\\d]{24})/discounts",
    component: _4f2049ae,
    name: "shop-discounts"
  }, {
    path: "/:shop([a-f\\d]{24})/ips",
    component: _6b58e400,
    name: "shop-ips"
  }, {
    path: "/:shop([a-f\\d]{24})/login",
    component: _5df9a480,
    name: "shop-login"
  }, {
    path: "/:shop([a-f\\d]{24})/logout",
    component: _70033f34,
    name: "shop-logout"
  }, {
    path: "/:shop([a-f\\d]{24})/newsSubscription",
    component: _3b88b328,
    name: "shop-newsSubscription"
  }, {
    path: "/:shop([a-f\\d]{24})/orders",
    component: _4255cbf8,
    name: "shop-orders"
  }, {
    path: "/:shop([a-f\\d]{24})/payments",
    component: _b7cce968,
    name: "shop-payments"
  }, {
    path: "/:shop([a-f\\d]{24})/pos",
    component: _72bdc6aa,
    name: "shop-pos"
  }, {
    path: "/:shop([a-f\\d]{24})/posScreen",
    component: _7b386eb4,
    name: "shop-posScreen"
  }, {
    path: "/:shop([a-f\\d]{24})/products",
    component: _3599967a,
    name: "shop-products"
  }, {
    path: "/:shop([a-f\\d]{24})/profile",
    component: _59fc0fc0,
    name: "shop-profile"
  }, {
    path: "/:shop([a-f\\d]{24})/promotions",
    component: _4b18a8e2,
    name: "shop-promotions"
  }, {
    path: "/:shop([a-f\\d]{24})/register",
    component: _151b4f42,
    name: "shop-register"
  }, {
    path: "/:shop([a-f\\d]{24})/settings",
    component: _650efcc2,
    name: "shop-settings"
  }, {
    path: "/:shop([a-f\\d]{24})/shippings",
    component: _63e046c8,
    name: "shop-shippings"
  }, {
    path: "/:shop([a-f\\d]{24})/shopUsers",
    component: _06d0c6ee,
    name: "shop-shopUsers"
  }, {
    path: "/:shop([a-f\\d]{24})/specs",
    component: _dbc23322,
    name: "shop-specs"
  }, {
    path: "/:shop([a-f\\d]{24})/switch",
    component: _5df5c5e0,
    name: "shop-switch"
  }, {
    path: "/:shop([a-f\\d]{24})/domoreTest/label",
    component: _04432dda,
    name: "shop-domoreTest-label"
  }, {
    path: "/:shop([a-f\\d]{24})/domoreTest/printer",
    component: _45d96979,
    name: "shop-domoreTest-printer"
  }, {
    path: "/:shop([a-f\\d]{24})/domoreTest/test",
    component: _61d7bfc3,
    name: "shop-domoreTest-test"
  }, {
    path: "/:shop([a-f\\d]{24})/domoreTest/weight",
    component: _67f94f2e,
    name: "shop-domoreTest-weight"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/purchasingOrders",
    component: _08d3f664,
    name: "shop-erp-purchasingOrders"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/wholesaleOrders",
    component: _0f2d90ee,
    name: "shop-erp-wholesaleOrders"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/articles",
    component: _4f4c25e0,
    name: "shop-eshop-articles"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/banners",
    component: _5e93462a,
    name: "shop-eshop-banners"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/brands",
    component: _7242f7df,
    name: "shop-eshop-brands"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/categories",
    component: _39658fa2,
    name: "shop-eshop-categories"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/contactField",
    component: _833b7166,
    name: "shop-eshop-contactField"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/contactForm",
    component: _1795c827,
    name: "shop-eshop-contactForm"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/ecshopArticles",
    component: _85c08a78,
    name: "shop-eshop-ecshopArticles"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/pages",
    component: _74a918e7,
    name: "shop-eshop-pages"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/relateds",
    component: _6472fabb,
    name: "shop-eshop-relateds"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/settings",
    component: _b3f5a114,
    name: "shop-eshop-settings"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/specs",
    component: _5c5e5f8a,
    name: "shop-eshop-specs"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/storeLocations",
    component: _1e1ac760,
    name: "shop-eshop-storeLocations"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/storeTags",
    component: _098f1a3d,
    name: "shop-eshop-storeTags"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/tags",
    component: _127067ec,
    name: "shop-eshop-tags"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/warranties",
    component: _a83921a6,
    name: "shop-eshop-warranties"
  }, {
    path: "/:shop([a-f\\d]{24})/extras/receipts",
    component: _29d1bbfe,
    name: "shop-extras-receipts"
  }, {
    path: "/:shop([a-f\\d]{24})/login/reset",
    component: _221e0740,
    name: "shop-login-reset"
  }, {
    path: "/:shop([a-f\\d]{24})/logs/actionLogs",
    component: _7a695cb9,
    name: "shop-logs-actionLogs"
  }, {
    path: "/:shop([a-f\\d]{24})/logs/adLogs",
    component: _54c3dba6,
    name: "shop-logs-adLogs"
  }, {
    path: "/:shop([a-f\\d]{24})/logs/orderPositionLogs",
    component: _4f28ad62,
    name: "shop-logs-orderPositionLogs"
  }, {
    path: "/:shop([a-f\\d]{24})/pos/cashBox",
    component: _289b239e,
    name: "shop-pos-cashBox"
  }, {
    path: "/:shop([a-f\\d]{24})/pos/openClose",
    component: _166999b2,
    name: "shop-pos-openClose"
  }, {
    path: "/:shop([a-f\\d]{24})/pos/punch",
    component: _8f48f1f2,
    name: "shop-pos-punch"
  }, {
    path: "/:shop([a-f\\d]{24})/pos/settings",
    component: _ceffdb46,
    name: "shop-pos-settings"
  }, {
    path: "/:shop([a-f\\d]{24})/products/skus",
    component: _1945ed15,
    name: "shop-products-skus"
  }, {
    path: "/:shop([a-f\\d]{24})/profile/changeInfo",
    component: _c93a5f00,
    name: "shop-profile-changeInfo"
  }, {
    path: "/:shop([a-f\\d]{24})/profile/changePassword",
    component: _1b65baed,
    name: "shop-profile-changePassword"
  }, {
    path: "/:shop([a-f\\d]{24})/profile/manage",
    component: _5048d2e7,
    name: "shop-profile-manage"
  }, {
    path: "/:shop([a-f\\d]{24})/register/verify",
    component: _26d3c4b9,
    name: "shop-register-verify"
  }, {
    path: "/:shop([a-f\\d]{24})/report/driver",
    component: _31f6a9fa,
    name: "shop-report-driver"
  }, {
    path: "/:shop([a-f\\d]{24})/report/driverReport",
    component: _728ceccb,
    name: "shop-report-driverReport"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/access",
    component: _6252f2b8,
    name: "shop-settings-access"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/email",
    component: _1393aa8c,
    name: "shop-settings-email"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/erpid",
    component: _340647ae,
    name: "shop-settings-erpid"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/integrations",
    component: _aa00ba42,
    name: "shop-settings-integrations"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/inventory",
    component: _92c0a768,
    name: "shop-settings-inventory"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/invoiceTemplates",
    component: _9ee7a468,
    name: "shop-settings-invoiceTemplates"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/newsSubscription",
    component: _2ad9bcd0,
    name: "shop-settings-newsSubscription"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/payment",
    component: _1f59d414,
    name: "shop-settings-payment"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/pointTypes",
    component: _6eb9e0ee,
    name: "shop-settings-pointTypes"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/pos",
    component: _1698a978,
    name: "shop-settings-pos"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/posBanners",
    component: _05b07fd6,
    name: "shop-settings-posBanners"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shippingCars",
    component: _ea26e6a6,
    name: "shop-settings-shippingCars"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shippingContainers",
    component: _2fb518c0,
    name: "shop-settings-shippingContainers"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shippingMethods",
    component: _6101b314,
    name: "shop-settings-shippingMethods"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shippingProviders",
    component: _3fec44f8,
    name: "shop-settings-shippingProviders"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shippingZones",
    component: _296b5cae,
    name: "shop-settings-shippingZones"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shop",
    component: _63c79d36,
    name: "shop-settings-shop"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shopRankGroups",
    component: _7f13ecf6,
    name: "shop-settings-shopRankGroups"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shopRanks",
    component: _181bd8e1,
    name: "shop-settings-shopRanks"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/sms",
    component: _e006a86e,
    name: "shop-settings-sms"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/social",
    component: _b89687a6,
    name: "shop-settings-social"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/socialMediaIntegrations",
    component: _5d21bf26,
    name: "shop-settings-socialMediaIntegrations"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/turncloud",
    component: _5213e94b,
    name: "shop-settings-turncloud"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/ui",
    component: _a63d4858,
    name: "shop-settings-ui"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/verify",
    component: _5554718e,
    name: "shop-settings-verify"
  }, {
    path: "/:shop([a-f\\d]{24})/shippings/driver",
    component: _2520fa64,
    name: "shop-shippings-driver"
  }, {
    path: "/:shop([a-f\\d]{24})/shippings/schedule",
    component: _47543b86,
    name: "shop-shippings-schedule"
  }, {
    path: "/:shop([a-f\\d]{24})/warehouses/inventories",
    component: _5c3ed11a,
    name: "shop-warehouses-inventories"
  }, {
    path: "/:shop([a-f\\d]{24})/warehouses/inventoryActions",
    component: _b9bb1512,
    name: "shop-warehouses-inventoryActions"
  }, {
    path: "/:shop([a-f\\d]{24})/warehouses/inventoryLogs",
    component: _46a3fccb,
    name: "shop-warehouses-inventoryLogs"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/wholesaleOrders/newOrder",
    component: _319a0022,
    name: "shop-erp-wholesaleOrders-newOrder"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/wholesaleOrders/quoSuccess",
    component: _1582914c,
    name: "shop-erp-wholesaleOrders-quoSuccess"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/contactField/values",
    component: _5ed46637,
    name: "shop-eshop-contactField-values"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/specs/values",
    component: _8d9209ee,
    name: "shop-eshop-specs-values"
  }, {
    path: "/:shop([a-f\\d]{24})/login/reset/reset",
    component: _6a3f1586,
    name: "shop-login-reset-reset"
  }, {
    path: "/:shop([a-f\\d]{24})/login/reset/verify",
    component: _8bafe70a,
    name: "shop-login-reset-verify"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/xero/callback",
    component: _0bf7b2ba,
    name: "shop-settings-xero-callback"
  }, {
    path: "/:shop([a-f\\d]{24})/warehouses/inventories/lots",
    component: _e91477ac,
    name: "shop-warehouses-inventories-lots"
  }, {
    path: "/:shop([a-f\\d]{24})/warehouses/inventories/summary",
    component: _20d44c24,
    name: "shop-warehouses-inventories-summary"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/settings/dialogs/PageSettingInfoDialog",
    component: _8ff36352,
    name: "shop-eshop-settings-dialogs-PageSettingInfoDialog"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/settings/pages/layout",
    component: _1d959561,
    name: "shop-eshop-settings-pages-layout"
  }, {
    path: "/:shop([a-f\\d]{24})/",
    component: _61848f68,
    name: "shop-dashboard-index"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/purchasingOrders/edit/:id?",
    component: _463b0b69,
    name: "shop-erp-purchasingOrders-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/wholesaleOrders/edit/:id?",
    component: _36f68b49,
    name: "shop-erp-wholesaleOrders-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/wholesaleOrders/monthlyStmt/:id?",
    component: _59ad063c,
    name: "shop-erp-wholesaleOrders-monthlyStmt-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/articles/edit/:id?",
    component: _0fa7b832,
    name: "shop-eshop-articles-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/banners/edit/:id?",
    component: _785806e6,
    name: "shop-eshop-banners-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/brands/edit/:id?",
    component: _a2e61a90,
    name: "shop-eshop-brands-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/categories/edit/:id?",
    component: _467f1068,
    name: "shop-eshop-categories-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/ecshopArticles/edit/:id?",
    component: _7ec909b3,
    name: "shop-eshop-ecshopArticles-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/pages/edit/:id?",
    component: _4d2a56a0,
    name: "shop-eshop-pages-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/relateds/edit/:id?",
    component: _4fa9b25c,
    name: "shop-eshop-relateds-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/extras/receipts/edit/:id?",
    component: _7d65c639,
    name: "shop-extras-receipts-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/comments/edit/:id?",
    component: _69d88e78,
    name: "shop-comments-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/couponTemplates/edit/:id?",
    component: _7fe0536d,
    name: "shop-couponTemplates-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/discounts/edit/:id?",
    component: _1f9a59a4,
    name: "shop-discounts-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/orders/edit/:id?",
    component: _30406273,
    name: "shop-orders-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/products/edit/:id?",
    component: _314adb54,
    name: "shop-products-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/promotions/edit/:id?",
    component: _6a0b6108,
    name: "shop-promotions-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/shippings/edit/:id?",
    component: _d1890e4a,
    name: "shop-shippings-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/shopUsers/edit/:id?",
    component: _5e9315ce,
    name: "shop-shopUsers-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/invoice/:id?",
    component: _6892f7ac,
    name: "shop-invoice-id"
  }, {
    path: "/:shop([a-f\\d]{24})/o/:id?",
    component: _620579e1,
    name: "shop-o-id"
  }, {
    path: "/:shop([a-f\\d]{24})/p/:id?",
    component: _6c32b280,
    name: "shop-p-id"
  }, {
    path: "/:shop([a-f\\d]{24})/*",
    component: _5a61c3b3,
    name: "shop-all"
  }, {
    path: "/cashboxLogs",
    component: _465ac3db,
    name: "cashboxLogs"
  }, {
    path: "/charts",
    component: _513311b1,
    name: "charts"
  }, {
    path: "/comments",
    component: _7f24cd53,
    name: "comments"
  }, {
    path: "/companyUsers",
    component: _4646f7ac,
    name: "companyUsers"
  }, {
    path: "/couponTemplates",
    component: _11615b4a,
    name: "couponTemplates"
  }, {
    path: "/dashboard",
    component: _61848f68,
    name: "dashboard"
  }, {
    path: "/discounts",
    component: _4f2049ae,
    name: "discounts"
  }, {
    path: "/ips",
    component: _6b58e400,
    name: "ips"
  }, {
    path: "/login",
    component: _5df9a480,
    name: "login"
  }, {
    path: "/logout",
    component: _70033f34,
    name: "logout"
  }, {
    path: "/newsSubscription",
    component: _3b88b328,
    name: "newsSubscription"
  }, {
    path: "/orders",
    component: _4255cbf8,
    name: "orders"
  }, {
    path: "/payments",
    component: _b7cce968,
    name: "payments"
  }, {
    path: "/pos",
    component: _72bdc6aa,
    name: "pos"
  }, {
    path: "/posScreen",
    component: _7b386eb4,
    name: "posScreen"
  }, {
    path: "/products",
    component: _3599967a,
    name: "products"
  }, {
    path: "/profile",
    component: _59fc0fc0,
    name: "profile"
  }, {
    path: "/promotions",
    component: _4b18a8e2,
    name: "promotions"
  }, {
    path: "/register",
    component: _151b4f42,
    name: "register"
  }, {
    path: "/settings",
    component: _650efcc2,
    name: "settings"
  }, {
    path: "/shippings",
    component: _63e046c8,
    name: "shippings"
  }, {
    path: "/shopUsers",
    component: _06d0c6ee,
    name: "shopUsers"
  }, {
    path: "/specs",
    component: _dbc23322,
    name: "specs"
  }, {
    path: "/switch",
    component: _5df5c5e0,
    name: "switch"
  }, {
    path: "/domoreTest/label",
    component: _04432dda,
    name: "domoreTest-label"
  }, {
    path: "/domoreTest/printer",
    component: _45d96979,
    name: "domoreTest-printer"
  }, {
    path: "/domoreTest/test",
    component: _61d7bfc3,
    name: "domoreTest-test"
  }, {
    path: "/domoreTest/weight",
    component: _67f94f2e,
    name: "domoreTest-weight"
  }, {
    path: "/erp/purchasingOrders",
    component: _08d3f664,
    name: "erp-purchasingOrders"
  }, {
    path: "/erp/wholesaleOrders",
    component: _0f2d90ee,
    name: "erp-wholesaleOrders"
  }, {
    path: "/eshop/articles",
    component: _4f4c25e0,
    name: "eshop-articles"
  }, {
    path: "/eshop/banners",
    component: _5e93462a,
    name: "eshop-banners"
  }, {
    path: "/eshop/brands",
    component: _7242f7df,
    name: "eshop-brands"
  }, {
    path: "/eshop/categories",
    component: _39658fa2,
    name: "eshop-categories"
  }, {
    path: "/eshop/contactField",
    component: _833b7166,
    name: "eshop-contactField"
  }, {
    path: "/eshop/contactForm",
    component: _1795c827,
    name: "eshop-contactForm"
  }, {
    path: "/eshop/ecshopArticles",
    component: _85c08a78,
    name: "eshop-ecshopArticles"
  }, {
    path: "/eshop/pages",
    component: _74a918e7,
    name: "eshop-pages"
  }, {
    path: "/eshop/relateds",
    component: _6472fabb,
    name: "eshop-relateds"
  }, {
    path: "/eshop/settings",
    component: _b3f5a114,
    name: "eshop-settings"
  }, {
    path: "/eshop/specs",
    component: _5c5e5f8a,
    name: "eshop-specs"
  }, {
    path: "/eshop/storeLocations",
    component: _1e1ac760,
    name: "eshop-storeLocations"
  }, {
    path: "/eshop/storeTags",
    component: _098f1a3d,
    name: "eshop-storeTags"
  }, {
    path: "/eshop/tags",
    component: _127067ec,
    name: "eshop-tags"
  }, {
    path: "/eshop/warranties",
    component: _a83921a6,
    name: "eshop-warranties"
  }, {
    path: "/extras/receipts",
    component: _29d1bbfe,
    name: "extras-receipts"
  }, {
    path: "/login/reset",
    component: _221e0740,
    name: "login-reset"
  }, {
    path: "/logs/actionLogs",
    component: _7a695cb9,
    name: "logs-actionLogs"
  }, {
    path: "/logs/adLogs",
    component: _54c3dba6,
    name: "logs-adLogs"
  }, {
    path: "/logs/orderPositionLogs",
    component: _4f28ad62,
    name: "logs-orderPositionLogs"
  }, {
    path: "/pos/cashBox",
    component: _289b239e,
    name: "pos-cashBox"
  }, {
    path: "/pos/openClose",
    component: _166999b2,
    name: "pos-openClose"
  }, {
    path: "/pos/punch",
    component: _8f48f1f2,
    name: "pos-punch"
  }, {
    path: "/pos/settings",
    component: _ceffdb46,
    name: "pos-settings"
  }, {
    path: "/products/skus",
    component: _1945ed15,
    name: "products-skus"
  }, {
    path: "/profile/changeInfo",
    component: _c93a5f00,
    name: "profile-changeInfo"
  }, {
    path: "/profile/changePassword",
    component: _1b65baed,
    name: "profile-changePassword"
  }, {
    path: "/profile/manage",
    component: _5048d2e7,
    name: "profile-manage"
  }, {
    path: "/register/verify",
    component: _26d3c4b9,
    name: "register-verify"
  }, {
    path: "/report/driver",
    component: _31f6a9fa,
    name: "report-driver"
  }, {
    path: "/report/driverReport",
    component: _728ceccb,
    name: "report-driverReport"
  }, {
    path: "/settings/access",
    component: _6252f2b8,
    name: "settings-access"
  }, {
    path: "/settings/email",
    component: _1393aa8c,
    name: "settings-email"
  }, {
    path: "/settings/erpid",
    component: _340647ae,
    name: "settings-erpid"
  }, {
    path: "/settings/integrations",
    component: _aa00ba42,
    name: "settings-integrations"
  }, {
    path: "/settings/inventory",
    component: _92c0a768,
    name: "settings-inventory"
  }, {
    path: "/settings/invoiceTemplates",
    component: _9ee7a468,
    name: "settings-invoiceTemplates"
  }, {
    path: "/settings/newsSubscription",
    component: _2ad9bcd0,
    name: "settings-newsSubscription"
  }, {
    path: "/settings/payment",
    component: _1f59d414,
    name: "settings-payment"
  }, {
    path: "/settings/pointTypes",
    component: _6eb9e0ee,
    name: "settings-pointTypes"
  }, {
    path: "/settings/pos",
    component: _1698a978,
    name: "settings-pos"
  }, {
    path: "/settings/posBanners",
    component: _05b07fd6,
    name: "settings-posBanners"
  }, {
    path: "/settings/shippingCars",
    component: _ea26e6a6,
    name: "settings-shippingCars"
  }, {
    path: "/settings/shippingContainers",
    component: _2fb518c0,
    name: "settings-shippingContainers"
  }, {
    path: "/settings/shippingMethods",
    component: _6101b314,
    name: "settings-shippingMethods"
  }, {
    path: "/settings/shippingProviders",
    component: _3fec44f8,
    name: "settings-shippingProviders"
  }, {
    path: "/settings/shippingZones",
    component: _296b5cae,
    name: "settings-shippingZones"
  }, {
    path: "/settings/shop",
    component: _63c79d36,
    name: "settings-shop"
  }, {
    path: "/settings/shopRankGroups",
    component: _7f13ecf6,
    name: "settings-shopRankGroups"
  }, {
    path: "/settings/shopRanks",
    component: _181bd8e1,
    name: "settings-shopRanks"
  }, {
    path: "/settings/sms",
    component: _e006a86e,
    name: "settings-sms"
  }, {
    path: "/settings/social",
    component: _b89687a6,
    name: "settings-social"
  }, {
    path: "/settings/socialMediaIntegrations",
    component: _5d21bf26,
    name: "settings-socialMediaIntegrations"
  }, {
    path: "/settings/turncloud",
    component: _5213e94b,
    name: "settings-turncloud"
  }, {
    path: "/settings/ui",
    component: _a63d4858,
    name: "settings-ui"
  }, {
    path: "/settings/verify",
    component: _5554718e,
    name: "settings-verify"
  }, {
    path: "/shippings/driver",
    component: _2520fa64,
    name: "shippings-driver"
  }, {
    path: "/shippings/schedule",
    component: _47543b86,
    name: "shippings-schedule"
  }, {
    path: "/warehouses/inventories",
    component: _5c3ed11a,
    name: "warehouses-inventories"
  }, {
    path: "/warehouses/inventoryActions",
    component: _b9bb1512,
    name: "warehouses-inventoryActions"
  }, {
    path: "/warehouses/inventoryLogs",
    component: _46a3fccb,
    name: "warehouses-inventoryLogs"
  }, {
    path: "/erp/wholesaleOrders/newOrder",
    component: _319a0022,
    name: "erp-wholesaleOrders-newOrder"
  }, {
    path: "/erp/wholesaleOrders/quoSuccess",
    component: _1582914c,
    name: "erp-wholesaleOrders-quoSuccess"
  }, {
    path: "/eshop/contactField/values",
    component: _5ed46637,
    name: "eshop-contactField-values"
  }, {
    path: "/eshop/specs/values",
    component: _8d9209ee,
    name: "eshop-specs-values"
  }, {
    path: "/login/reset/reset",
    component: _6a3f1586,
    name: "login-reset-reset"
  }, {
    path: "/login/reset/verify",
    component: _8bafe70a,
    name: "login-reset-verify"
  }, {
    path: "/settings/xero/callback",
    component: _0bf7b2ba,
    name: "settings-xero-callback"
  }, {
    path: "/warehouses/inventories/lots",
    component: _e91477ac,
    name: "warehouses-inventories-lots"
  }, {
    path: "/warehouses/inventories/summary",
    component: _20d44c24,
    name: "warehouses-inventories-summary"
  }, {
    path: "/eshop/settings/dialogs/PageSettingInfoDialog",
    component: _8ff36352,
    name: "eshop-settings-dialogs-PageSettingInfoDialog"
  }, {
    path: "/eshop/settings/pages/layout",
    component: _1d959561,
    name: "eshop-settings-pages-layout"
  }, {
    path: "/",
    component: _0f8206b4,
    name: "index"
  }, {
    path: "/erp/purchasingOrders/edit/:id?",
    component: _463b0b69,
    name: "erp-purchasingOrders-edit-id"
  }, {
    path: "/erp/wholesaleOrders/edit/:id?",
    component: _36f68b49,
    name: "erp-wholesaleOrders-edit-id"
  }, {
    path: "/erp/wholesaleOrders/monthlyStmt/:id?",
    component: _59ad063c,
    name: "erp-wholesaleOrders-monthlyStmt-id"
  }, {
    path: "/eshop/articles/edit/:id?",
    component: _0fa7b832,
    name: "eshop-articles-edit-id"
  }, {
    path: "/eshop/banners/edit/:id?",
    component: _785806e6,
    name: "eshop-banners-edit-id"
  }, {
    path: "/eshop/brands/edit/:id?",
    component: _a2e61a90,
    name: "eshop-brands-edit-id"
  }, {
    path: "/eshop/categories/edit/:id?",
    component: _467f1068,
    name: "eshop-categories-edit-id"
  }, {
    path: "/eshop/ecshopArticles/edit/:id?",
    component: _7ec909b3,
    name: "eshop-ecshopArticles-edit-id"
  }, {
    path: "/eshop/pages/edit/:id?",
    component: _4d2a56a0,
    name: "eshop-pages-edit-id"
  }, {
    path: "/eshop/relateds/edit/:id?",
    component: _4fa9b25c,
    name: "eshop-relateds-edit-id"
  }, {
    path: "/extras/receipts/edit/:id?",
    component: _7d65c639,
    name: "extras-receipts-edit-id"
  }, {
    path: "/comments/edit/:id?",
    component: _69d88e78,
    name: "comments-edit-id"
  }, {
    path: "/couponTemplates/edit/:id?",
    component: _7fe0536d,
    name: "couponTemplates-edit-id"
  }, {
    path: "/discounts/edit/:id?",
    component: _1f9a59a4,
    name: "discounts-edit-id"
  }, {
    path: "/orders/edit/:id?",
    component: _30406273,
    name: "orders-edit-id"
  }, {
    path: "/products/edit/:id?",
    component: _314adb54,
    name: "products-edit-id"
  }, {
    path: "/promotions/edit/:id?",
    component: _6a0b6108,
    name: "promotions-edit-id"
  }, {
    path: "/shippings/edit/:id?",
    component: _d1890e4a,
    name: "shippings-edit-id"
  }, {
    path: "/shopUsers/edit/:id?",
    component: _5e9315ce,
    name: "shopUsers-edit-id"
  }, {
    path: "/invoice/:id?",
    component: _6892f7ac,
    name: "invoice-id"
  }, {
    path: "/o/:id?",
    component: _620579e1,
    name: "o-id"
  }, {
    path: "/p/:id?",
    component: _6c32b280,
    name: "p-id"
  }, {
    path: "/*",
    component: _5a61c3b3,
    name: "all"
  }],

  parseQuery: function(query) {
      return require('qs').parse(query);
    },
  stringifyQuery: function(query) {
      var result = require('qs').stringify(query);
      return result ? '?' + result : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
