
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync } from "nuxt-property-decorator";
import { PosOrder } from "~/plugins/pos/order";
import { FindPopRawType, FindType, checkID } from "@feathers-client";
import BBPOS from "~/mixins/bbpos";
import AllinpayPOS from "~/mixins/allinpay";
import Printer from "~/mixins/Printer";
import type { PaymentType, PaymentMethod } from "@common/common";
import type Cash from "./cash.vue";
import type PayPoint from "./payPoint.vue";
import { RazerPayHeader, RazerPayManager, RazerPayMessage, RazerPaymentMethod } from "pos-printer/razerpay";
import "~/plugins/payments";
const BASE62 = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
import basex from "base-x";
const bs62 = basex(BASE62);
import { ResponseCode, TurnCloudError, TurnCloudMessage, TurnCloudConnectionError } from "pos-printer/turncloud";
import {
  OctopusCommandRespTransactionError,
  OctopusCommandRespTransactionSuccess,
  OctopusCommandTransaction,
} from "pos-printer/octopus/lib";
import {
  CloudPayload,
  PaymentError,
  PaymentLike,
  PaymentMethodBase,
  PaymentOptions,
} from "pos-printer/payments/methodBase";
import { LangArrType } from "@feathers-client/i18n";
import Octopus from "pos-printer/octopus/OctopusOptions.vue";
import { playAudioFile } from "@feathers-client/qrcode-scanner/beep";

// @ts-ignore
import AUDIO_FILE from "!file-loader!~/assets/audio/payment-success.mp3";

export type PaymentMethodFlatten = FindType<"shop/payment/methods"> & {
  methodId?: any;
};

@Component
export default class OrderSystemCheckout extends mixins(BBPOS, AllinpayPOS, Printer("thermal", "thermal")) {
  @Prop()
  session: PosOrder;

  @Ref()
  cash: Cash;

  @Ref()
  point: PayPoint;

  viewPayment: FindType<"shop/payments"> = null;

  editingMethod = false;

  toggleRemark = false;

  @PropSync("change", { default: "" })
  changeStr: string;

  priceStr: string;

  amountNumPadDialog = false;
  tipsNumPadDialog = false;
  get numPadEnabled() {
    return this.amountNumPadDialog || this.tipsNumPadDialog;
  }

  @Watch("numPadEnabled")
  onWatchNumPadState() {
    if (this.numPadEnabled) {
      this.$store.commit("SET_NUMPAD", this.numPadEnabled);
    }
    if (!this.amountNumPadDialog && !this.tipsNumPadDialog) {
      this.$store.commit("SET_NUMPAD", false);
    }
  }

  checkoutTab = "method";
  get checkoutTabs() {
    return [
      !this.session._id && this.$shop.shopType === "eshop" && !this.$pos.kioskMode ? ["orderType"] : [],
      this.orderType === "offline" ? ["method"] : [],
      !this.$pos.kioskMode ? ["partial"] : [],
      !this.$pos.kioskMode ? ["discount"] : [],
      this.session.user && this.$pos.pointDiscounts.length ? ["point"] : [],
      this.session?.payments?.length ? ["record"] : [],
      this.$config.features.tax ? ["tax"] : [],
    ].flat();
  }

  get splitPayment() {
    return this.paymentAmountInt !== this.session.outstandingInt;
  }

  get paymentMethodsRaw() {
    return this.$pos.kioskMode
      ? this.$pos.paymentMethods.filter(
          it => it.type !== "cash" && it.type !== "custom" && (this.session.user || it.type !== "point"),
        )
      : this.$pos.paymentMethods;
  }

  @Watch("session.outstandingInt")
  onOutstandingChanged() {
    this.paymentAmountInt = this.session.outstandingInt;
  }

  get paymentMethods(): PaymentMethodFlatten[] {
    return this.paymentMethodsRaw.flatMap(method => {
      switch (method.type) {
        case "bbpos":
        case "razer":
        case "allinpay":
        case "turnCloud":
          if (method.options?.methods?.length) {
            return method.options.methods.map(it => ({
              ...method,
              _id: method._id + it,
              methodId: method._id,
              options: {
                ...method.options,
                method: it,
              },
              name: {
                $join: [method.name, " ", { $t: "paymentMethods." + it }],
              },
            }));
          }
          break;
        default: {
          const manager = this.$paymentManager.getMethod(method.type);
          if (manager) {
            const subMethods = manager.subMethods;
            if (subMethods) {
              return subMethods.map(subMethod => ({
                ...method,
                _id: method._id + subMethod.subType,
                name: subMethod.name,
                methodId: method._id,
                subType: subMethod.subType,
                options: {
                  ...(method.options || {}),
                  ...(subMethod.props || {}),
                  method: subMethod.subType,
                },
              }));
            }
          }

          break;
        }
      }
      return [method];
    });
  }

  loading = false;
  cancelling = false;

  paymentAmountInt = 0;

  @Watch("paymentAmountInt", { immediate: true })
  onPaymentAmountInt(v) {
    this.$emit("update:paymentAmountInt", v);
  }

  get payLoading() {
    return this.loading || this.cancelling;
  }

  payLoadingTimer: any;

  @Watch("payLoading", { immediate: true })
  onPayLoading(v, ov) {
    if (v === ov) return;
    if (this.payLoadingTimer) {
      clearTimeout(this.payLoadingTimer);
    }
    if (v) {
      this.$emit("update:payLoading", v);
    } else {
      this.payLoadingTimer = setTimeout(() => {
        this.payLoadingTimer = null;
        this.$emit("update:payLoading", v);
      }, 100);
    }
  }

  @Watch("canPay", { immediate: true })
  onCanPay(v) {
    this.$emit("update:canPay", v);
  }

  currentPayment: PaymentType = null;
  paymentMethod: PaymentMethodFlatten = null;
  paymentPromise: Promise<boolean> = null;
  paymentPromiseCancel: AbortController = null;

  @PropSync("cashValidCart")
  cashValidSync: boolean;

  pointValid = false;

  get orderTypes() {
    return [
      { name: this.$t("orderType.online"), _id: "online" },
      { name: this.$t("orderType.offline"), _id: "offline" },
    ];
  }

  orderType: "online" | "offline" = "online";

  get canPay() {
    return (
      this.orderType === "online" ||
      (this.paymentMethod &&
        (this.paymentMethod.type !== "cash" || this.cashValidSync) &&
        (this.paymentMethod.type !== "point" || this.pointValid) &&
        (!this.$shop.posCheckoutFenceId || this.$pos?.ipsManager?.fenceId === this.$shop.posCheckoutFenceId))
    );
  }

  close(result?: boolean) {
    (this as any).modalResult(result);
  }

  portrait = false;
  portraitListener: MediaQueryList;

  async mounted() {
    if (!this.session._id && this.$shop.shopType === "eshop") {
      this.checkoutTab = "orderType";
    }
    await this.reloadPayment(null, this.session._id);
    this.paymentMethod = this.paymentMethods[0];
    this.paymentAmountInt = this.session.outstandingInt;
    this.orderType = this.$store.state.shop?.shopType === "eshop" ? "online" : "offline";
    this.portraitListener = window.matchMedia("(orientation: portrait)");
    this.portraitListener.addEventListener("change", this.updatePortrait);
    this.portrait = this.portraitListener.matches;
    this.viewPayment = this.session?.payments?.at?.(-1) ?? null;
  }

  beforeDestroy() {
    if (this.portraitListener) {
      this.portraitListener.removeEventListener("change", this.updatePortrait);
    }
  }

  updatePortrait() {
    this.portrait = this.portraitListener.matches;
  }

  @Watch("session._id")
  async reloadPayment(v, ov) {
    if (v === ov) return;
    if (this.session._id) {
      this.currentPayment = (
        await this.$feathers.service("shop/payments").find({
          query: {
            $sort: { date: -1 },
            order: this.session._id,
            $limit: 1,
          },
        })
      ).data[0];
    }
  }

  @Watch("priceStr")
  onWatchReceive(newV, oldV) {
    console.log(newV, oldV);
  }

  async navigateAndPay() {
    this.checkoutTab = "method";

    if (!this.$breakpoint.mdAndUp) {
      const confirmPayment = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog2.vue"),
        {
          title: this.$t("pos.mobile.confirmPayment.$"),
          content: this.$t("pos.mobile.confirmDialog.$", {
            totalPrice: this.$price(this.session.amountInt),
            receivedAmount: this.priceStr ? this.priceStr : this.$price(this.session.amountInt),
            changeAmount: this.changeStr ? this.changeStr : 0,
          }),
        },
        {
          maxWidth: "calc(min(90vw,400px))",
        },
      );
      if (!confirmPayment) return;
    }

    await Vue.nextTick();
    return this.tryPay();
  }

  async tryPay() {
    if (await this.cancelCurrentPayment()) {
      return true;
    }
    if (!(await this.checkTaxStatus())) {
      return false;
    }
    this.paymentPromiseCancel = new AbortController();
    this.paymentPromise = this.tryPayInner(this.paymentPromiseCancel.signal);
    return this.paymentPromise;
  }

  async tryPayInner(controller: AbortSignal) {
    const method = this.paymentMethod;
    this.loading = true;
    this.status = "pending";
    this.currentPaymentCancellable = true;

    let payment: PaymentType;
    try {
      await this.session.ensureOrder();

      if (this.orderType === "online") {
        this.session.status = "done";
        return true;
      }

      if (this.session.status === "done" || this.session.status === "confirmed") {
        return true;
      }

      if (controller.aborted) throw new Error("Aborted");
      this.currentPaymentCancellable = false;
      switch (method.type) {
        case "cash": {
          const received = this.cash?.apply?.() ?? null;
          if (received === null) {
            throw new Error("Invalid received");
          }
          payment = await this.createPayment(method, {
            receiveAmount: received,
          });
          this.changeStr = "";
          break;
        }
        case "point": {
          const received = this.point?.apply?.() ?? null;
          if (received === null) {
            throw new Error("Invalid received");
          }
          payment = await this.createPayment(method, {
            usePoint: received,
          });
          break;
        }
        case "bbpos": {
          this.currentPaymentCancellable = true;
          payment = await this.createPayment(method, null, true);

          console.log("new payment");
          const resp = await Promise.race([
            this.callPOS({
              apiVersion: 21,
              amount: this.$pos.getHumanNumber(payment.amountInt),
              transactionType: "SALE",
              isExternal: true,
              payMethod: method.options?.method,
              showReceipt: method.options?.showReceipt ?? false,
              invoiceNumber: payment._id,
            }),
            new Promise((resolve, reject) => controller.addEventListener("abort", reject, { once: true })),
          ]);
          if (resp?.WAPIResult?.state?.[0] !== "COMPLETE") {
            throw new Error("Not completed");
          }
          const methodNetwork = (
            resp?.WAPIResult?.cardData?.[0]?.cardType?.[0] ??
            resp?.WAPIResult?.entryMode?.[0] ??
            ""
          ).toLowerCase();
          payment = await this.confirmPayment(payment, resp, {
            methodNetwork,
            reference: resp?.WAPIResult?.receiptData?.[0]?.retrievalReferenceNumber?.[0],
          });
          this.loading = false;
          break;
        }
        case "allinpay": {
          this.currentPaymentCancellable = true;
          console.log("new payment");
          const currency = this.paymentMethod.options.currency || "HKD";
          payment = await this.createPayment(method, null, true, {
            currency,
          });

          try {
            const resp: any = await Promise.race([
              this.callAllinpayPOS({
                BUSINESS_ID:
                  payment.subMethod === "CREDIT"
                    ? "100100001"
                    : payment.subMethod === "SCAN"
                    ? "100300001"
                    : "100300002",
                AMOUNT: Math.round(payment.amountInt / 100)
                  .toString()
                  .padStart(12, "0"),
                TRANS_TRACE_NO: String(payment._id),
                TRANS_ORDER_NO: String(this.session._id),
                CURRENCY: currency,
              }),
              new Promise((resolve, reject) => controller.addEventListener("abort", reject, { once: true })),
            ]);
            const check = await this.callAllinpayPOS({
              BUSINESS_ID: "600000002", // BUSI_QUERY_ORDER_RESULT
              TRANS_TRACE_NO: String(payment._id),
            });
            payment = await this.confirmAllinpayPayment(payment, resp);
          } catch (e) {
            if (e.REJCODE === "1FF") {
              payment = await this.$feathers.service("shop/payments/update").create({
                _id: payment._id,
                status: "cancelled",
              });
              this.currentPayment = null;
            }
            throw e;
          }

          this.loading = false;
          break;
        }
        case "razer": {
          this.currentPaymentCancellable = true;
          (window as any).cancelRazer = this.cancelCurrentPayment;
          if (!this.$paymentManager.razer) {
            throw new Error(String(this.$t("razer.notSetup")));
          }
          payment = await this.createPayment(method, null, true, {});

          const header = new RazerPayHeader();
          header.transactionCode = "20";
          const payload: Partial<RazerPayMessage> = {
            header,
            body: {
              payAccountId: "00000000000000000000", // This field is use in another project
              transactionId: bs62.encode(Buffer.from(String(payment._id), "hex")),
              amount: Math.round(payment.amountInt / 100)
                .toString()
                .padStart(12, "0"),
              ...(RazerPaymentMethod[payment.subMethod] == RazerPaymentMethod.CREDIT
                ? {}
                : { walletProductId: RazerPaymentMethod[payment.subMethod] }),
            },
          };

          await this.$paymentManager.razer.waitReady(true);
          const resp = await this.$paymentManager.razer.doTransaction(payload);
          payment = await this.confirmRazerPayment(payment, resp);
          this.loading = false;
          break;
        }
        case "turnCloud": {
          if (!this.$paymentManager.turnCloud) {
            throw new Error(String(this.$t("turnCloud.notSetup")));
          }
          let received: number = null;
          if (this.paymentMethod.options.method === "cash") {
            received = this.cash?.apply?.() ?? null;
            if (received === null) {
              throw new Error("Invalid received");
            }
          }
          payment = await this.createPayment(
            method,
            {
              receiveAmount: received,
            },
            true,
            {},
          );
          const payload: TurnCloudMessage = {
            payType: payment.subMethod as any,
            actionType: "sale",
            orderNo: String(payment._id),
            transAmount: this.$pos.getHumanNumber(payment.amountInt),
          };

          if (method.options?.turnCloudTaxEnabled) {
            payload.taxType = "taxable";
            payload.taxRate = this.session.taxes?.[0]?.percent ?? 0;
            payload.taxAmount = this.$pos.getHumanNumber(this.session.taxes?.[0]?.totalInt ?? 0);
            payload.salesAmount = this.$pos.getHumanNumber(payment.amountInt - payload.taxAmount);
          }

          if (payment.subMethod === "qrCode") {
            payload.transMethod = "reverse";
          }

          payment = await this.$feathers.service("shop/payments/update").create({
            _id: payment._id,
            metadata: payload,
          });

          payment = await this.doTurnCloudPayment(payment);
          break;
        }

        case "octopus": {
          if (!this.$paymentManager.octopus) {
            throw new Error(String(this.$t("octopus.notSetup")));
          }
          if (this.session.outstandingInt !== this.paymentAmountInt) {
            throw new Error(String(this.$t("octopus.needToPayFullAmount")));
          }
          this.currentPaymentCancellable = true;
          payment = await this.createPayment(
            method,
            {
              deviceId: this.$paymentManager.octopus.version.devId,
              locId: this.$paymentManager.octopus.version.locId,
            },
            true,
          );

          payment = await this.doOctopusPayment(payment, controller);

          break;
        }

        case "custom": {
          payment = await this.createPayment(method);
          break;
        }

        default: {
          const manager = this.$paymentManager.getMethod(method.type);
          if (!manager) {
            throw new Error("Invalid payment method");
          }

          this.currentPaymentCancellable = !!manager.capabilities.cancel;

          const paymentLike: PaymentLike = {
            amount: this.$pos.getHumanNumber(this.paymentAmountInt),
            status: "pending",
            subType: method.options.method,
            props: method.options,
          };

          if (manager.capabilities.authData) {
            if (manager.capabilities.authData === "qrcode") {
              paymentLike.authData = await this.scanCode();
            } else {
              throw new Error("Invalid payment method");
            }
          }

          await manager.waitReady(true);
          await manager.beginPay(paymentLike);

          if (manager.capabilities.cloud) {
            payment = await this.createPayment(method, paymentLike.metadata || {}, true, {
              authData: paymentLike.authData,
            });
          } else {
            payment = await this.createPayment(method, paymentLike.metadata || {}, true);
          }

          if (payment.status === "pending") {
            payment = await this.doGenericPayment(manager, payment, controller);
          }

          break;
        }
      }
      if (payment.status === "paid") {
        this.$pos.analyticsManager.logOrderEvent("payment", this.session._id, { payment });

        await this.finishOrder(payment);
        return this.session.paymentStatus === "paid";
      } else if (payment.status === "error") {
        this.$pos.analyticsManager.logOrderEvent("paymentFail", this.session._id, {
          payment,
          error: payment.errorMessage || this.errorMessage,
        });
        this.currentPayment = null;
        if (!this.errorMessage) {
          this.setError(payment.errorMessage || "Unknown error");
        }
      } else if (payment.status !== "pending") {
        this.currentPayment = null;
        this.$pos.analyticsManager.logOrderEvent("cancelPayment", this.session._id, { payment });
      } else {
        throw new Error("Unknown error");
      }
      return false;
    } catch (e) {
      this.$pos.analyticsManager.logOrderEvent("paymentFail", this.session._id, { error: e.message });
      this.$store.commit("SET_ERROR", e.message);
      console.error(e);
      this.setError(e.messaget || e.message);
    } finally {
      this.loading = false;
      this.paymentPromise = null;
      this.paymentPromiseCancel = null;
    }
  }

  async cloudInvoke(paymentLike: PaymentLike, payload: CloudPayload, options?: PaymentOptions) {
    const resp = await this.$feathers.service("shop/payments/update").create({
      _id: paymentLike._id,
      payload,
    });

    paymentLike.status = resp.status;
    paymentLike.metadata = resp.metadata;
    paymentLike.errorMessage = resp.errorMessage;

    return paymentLike;
  }

  async doGenericPayment(
    method: PaymentMethodBase,
    payment: FindType<"shop/payments">,
    signal?: AbortSignal,
    restore?: boolean,
  ) {
    let paymentLike: PaymentLike = {
      _id: payment._id,
      amount: this.$pos.getHumanNumber(payment.amountInt),
      status: "pending",
      metadata: payment.metadata,
      subType: payment.subMethod,
    };
    if (method.capabilities.cancel && signal) {
      this.currentPaymentCancellable = true;
    }
    let paymentResult: PaymentLike = paymentLike;
    try {
      if (restore) {
        paymentLike =
          (await method.restore(paymentLike, {
            signal,
            statusCb: status => {
              this.statusMessage = status as any;
            },
            cloudInvoke: this.cloudInvoke,
          })) || paymentLike;
      } else {
        paymentLike =
          (await method.pay(paymentLike, {
            signal,
            statusCb: status => {
              this.statusMessage = status as any;
            },
            subscribe: async () => {
              return new Promise((resolve, reject) => {
                const updateHandler = (payment: PaymentType) => {
                  if (payment.status !== "pending") {
                    paymentLike.status = payment.status as any;
                    paymentLike.metadata = payment.metadata;
                    paymentLike.errorMessage = payment.errorMessage;
                    cleanUp();
                    resolve(paymentLike);
                  }
                };
                this.$feathers.service("shop/payments").on("patched", updateHandler);

                const timer = setInterval(async () => {
                  try {
                    payment = await this.$feathers.service("shop/payments").get(payment._id);
                    if (payment.status !== "pending") {
                      paymentLike.status = payment.status as any;
                      paymentLike.metadata = payment.metadata;
                      paymentLike.errorMessage = payment.errorMessage;
                      cleanUp();
                      resolve(paymentLike);
                    }
                  } catch (e) {
                    console.warn(e);
                  }
                }, 15000);

                if (signal) {
                  signal.addEventListener(
                    "abort",
                    () => {
                      cleanUp();
                      setTimeout(() => {
                        reject(new Error("Aborted"));
                      }, 500);
                    },
                    { once: true },
                  );
                }

                const cleanUp = () => {
                  this.$feathers.service("shop/payments").off("patched", updateHandler);
                  clearInterval(timer);
                };
              });
            },
            cloudInvoke: this.cloudInvoke,
          })) || paymentLike;
      }
    } catch (e) {
      if (e instanceof PaymentError) {
        if (e.payment) {
          paymentLike = e.payment;
        }
      } else if (e.message === "Aborted") {
      } else {
        throw e;
      }
    }

    if (method.capabilities.cloud) {
      payment = await this.$feathers.service("shop/payments").get(payment._id);
      if (paymentResult.status !== "paid") {
        if (paymentResult.errorMessage) {
          this.setError(paymentResult.errorMessage);
        }
      }
    } else {
      if (paymentResult.status === "paid") {
        payment = await this.confirmPayment(payment, paymentResult.metadata || {}, {
          amountInt: this.$pos.fromHumanNumberToRaw(paymentResult.amount),
          surchargeInt: this.$pos.fromHumanNumberToRaw(paymentResult.surcharge),
          metadata: paymentResult.metadata,
          errorMessage: paymentResult.errorMessage,
          methodNetwork: paymentResult.network,
        });
      } else {
        if (paymentResult.errorMessage) {
          this.setError(paymentResult.errorMessage);
        }
        payment = await this.$feathers.service("shop/payments/update").create({
          _id: payment._id,
          status: paymentResult.status === "cancelled" ? "cancelled" : "error",
          metadata: paymentResult.metadata,
          errorMessage: paymentResult.errorMessage,
        });
      }
    }

    return payment;
  }

  async createPayment(
    method: PaymentMethodFlatten,
    metadata: any = {},
    pending = false,
    extras: Record<string, any> = {},
  ) {
    if (this.currentPayment) throw new Error("Invalid state");
    this.currentPayment = await this.$feathers.service("shop/payments/create").create({
      method: method.methodId || method._id,
      subMethod: method.options?.method,
      amountInt: this.paymentAmountInt,
      order: this.session._id,
      status: pending ? "pending" : "paid",
      metadata: metadata,
      ...extras,
    });
    return this.currentPayment;
  }

  async confirmAllinpayPayment(payment: FindType<"shop/payments">, resp: any) {
    const network = ["UNION PAY", "VISA", "MASTERCARD", "JCD", "DINNERS", "AE"][+resp.CARD_ORGN] || resp.CARD_ORGN;
    return await this.confirmPayment(payment, resp, {
      methodNetwork: network,
      amountInt: +(resp.PAY_AMOUNT || resp.AMOUNT) / 100,
      reference: resp.REF_NO,
      metadata: {
        AUTH_NO: resp.AUTH_NO,
        BATCH_NO: resp.BATCH_NO,
        CARD_NO: (resp.CARDNO || "").replace(/(?!\d{0,4}$)\d/g, "*"),
        CURRENCY: resp.CURRENCY,
        DATE: resp.DATE,
        MERCH_ID: resp.MERCH_ID,
        REF_NO: resp.REF_NO,
        TER_ID: resp.TER_ID,
        TIME: resp.TIME,
        TRACE_NO: resp.TRACE_NO,
        TRANS_CHANNEL: resp.TRANS_CHANNEL,
        TRANS_TRACE_NO: resp.TRANS_TRACE_NO,
      },
    });
  }

  async confirmRazerPayment(payment: PaymentType, resp: any) {
    return await this.confirmPayment(payment, resp, {
      amountInt: +resp.body.amount / 100 ?? 0,
    });
  }

  async cancelAllinpayPOS(payment: FindPopRawType<"method", "shop/payments">) {
    try {
      const resp = await this.callAllinpayPOS({
        BUSINESS_ID: "600000002", // BUSI_QUERY_ORDER_RESULT
        TRANS_TRACE_NO: String(payment._id),
      });
      if (resp.TRANS_STATE === "PAIED" || resp.CARD_ORGN) {
        console.log("Get the old paid payment");
        if (resp.CARD_ORGN) {
          // try to find refund order
          try {
            const resp = await this.callAllinpayPOS({
              BUSINESS_ID: "600000002", // BUSI_QUERY_ORDER_RESULT
              TRANS_TRACE_NO: String(payment._id) + "REF",
            });
            if (resp) {
              payment = (await this.$feathers.service("shop/payments/update").create({
                _id: payment._id,
                status: "refunded",
              })) as any;
              return payment;
            }
          } catch (e) {}
        }
        payment = (await this.confirmAllinpayPayment(payment as any, resp)) as any;
        return;
      } else if (resp?.TRANS_STATE === "PAYING") {
        throw new Error("Paying");
      }
    } catch (e) {
      if (e.REJCODE === "1FF") {
        payment = (await this.$feathers.service("shop/payments/update").create({
          _id: payment._id,
          status: "cancelled",
        })) as any;
        return payment;
      }
      return false;
    }
    return payment;
  }

  async confirmPayment(payment: PaymentType, metadata: any = {}, opts: Partial<PaymentType> = {}) {
    if (payment.status !== "paid" && payment.status !== "pending") {
      throw new Error();
    }
    if (payment.status !== "paid") {
      this.currentPayment = await this.$feathers.service("shop/payments/update").create({
        ...opts,
        _id: payment._id,
        status: "paid",
        metadata,
      });
      if (this.currentPayment && this.currentPayment.status === "paid") {
        playAudioFile(AUDIO_FILE, 100);
        await this.$openDialog(
          import("~/components/hkpc/ReviewDialog.vue"),
          {
            order: this.session,
            user: this.session.user,
          },
          {
            persistent: true,
          },
        );
      }
      return this.currentPayment;
    }
    return payment;
  }

  async cancelBBPOS(payment: FindPopRawType<"method", "shop/payments">) {
    try {
      const resp = await this.callPOS({
        apiVersion: 21,
        transactionType: "QUERY_LAST_TRANSACTION",
        invoiceNumber: payment._id,
      });
      if (resp?.WAPIResult?.state?.[0] === "COMPLETE") {
        console.log("Get the old paid payment");
        const methodNetwork = (
          resp?.WAPIResult?.cardData?.[0]?.cardType?.[0] ??
          resp?.WAPIResult?.entryMode?.[0] ??
          ""
        ).toLowerCase();
        const addons: any = {};
        switch (methodNetwork) {
          case "visa":
          case "master":
            addons["method"] = addons["methodSubType"] = "CREDIT";
            break;
          case "alipay":
            addons["method"] = addons["methodSubType"] = "ALIPAY";
            "ALIPAY";
            break;
          case "wechat":
            addons["method"] = addons["methodSubType"] = "WECHAT";
            break;
          case "octopus":
            addons["method"] = addons["methodSubType"] = "OCTOPUS";
            break;
        }
        console.log("Get the old paid payment");
        payment = (await this.confirmPayment(payment as any, resp, {
          reference: resp?.WAPIResult?.receiptData?.[0]?.retrievalReferenceNumber?.[0],
        })) as any;
      }
    } catch (e) {
      // this.$store.commit("SET_ERROR", `No old paid payment: ${e.message}`);
      console.log("No old paid payment", e);
      if (e.resultCode === "RESULT_BUSY") {
        await this.cancelPOS();
        return;
      }
    }
    return payment;
  }

  async cancelRazer(payment: FindPopRawType<"method", "shop/payments">) {
    try {
      await this.$paymentManager.razer.waitReady(true);
      await this.$paymentManager.razer.cancelPayment(bs62.encode(Buffer.from(String(payment._id), "hex")));
      // FETCH
      const header = new RazerPayHeader();
      header.transactionCode = "12";
      const payload: Partial<RazerPayMessage> = {
        header,
        body: {
          transactionId: bs62.encode(Buffer.from(String(payment._id), "hex")),
        },
      };
      const resp = await this.$paymentManager.razer.doTransaction(payload, true, false);
      // CONFIRM
      if (
        (resp.header.responseCode === "00" || resp.header.responseCode === "WE") &&
        (resp.header.transactionCode === "12" || resp.header.transactionCode === "20")
      ) {
        return await this.confirmRazerPayment(payment as any, resp);
      }
      if (resp.header.responseCode === "NF") {
        // not found
        this.$store.commit("SET_ERROR", resp.body.responseText);
        return await this.$feathers.service("shop/payments/update").create({
          _id: payment._id,
          status: "error",
          errorMessage: resp.body.responseText,
        });
      }
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
      return await this.$feathers.service("shop/payments/update").create({
        _id: payment._id,
        status: "error",
        errorMessage: e.message,
      });
    }
  }

  async cancelOctopus(payment: FindType<"shop/payments">) {
    if (checkID(this.currentPayment, payment)) {
      await this.$paymentManager.octopus.cancel();
      payment = await this.$feathers.service("shop/payments/update").create({
        _id: payment._id,
        status: "cancelled",
      });
      return payment;
    } else {
      return await this.doOctopusPayment(payment);
    }
  }

  async doOctopusPayment(payment: FindType<"shop/payments">, signal?: AbortSignal) {
    const amount = Math.ceil(this.$pos.getHumanNumber(payment.amountInt) * 10);
    let transaction: OctopusCommandRespTransactionSuccess | OctopusCommandRespTransactionError;
    let txnId = +payment.tranID | 0;
    let transactionStarted = false;
    let errorMessage;
    try {
      const input: Partial<OctopusCommandTransaction> = {
        type: "transaction",
        txnId,
        amount,
        pollSeconds: 30,
        orderInfo: `sessionId=${this.session._id},sessionName=${this.session.orderId}`,
      };
      const progress = status => {
        if (status === "starting") {
          this.status = "paying";
          transactionStarted = true;
        }
      };
      let cancelRetry = false;
      let mustRetrying = false;
      const retry = async (transaction: OctopusCommandRespTransactionError, retry: "mustRetry" | "retry") => {
        if (cancelRetry) {
          this.resetError();
          return false;
        }
        if (mustRetrying || retry === "mustRetry") {
          mustRetrying = true;
          this.currentPaymentCancellable = false;
          this.errorRetry = retry => {
            if (!retry) {
              cancelRetry = true;
            }
          };
          this.errorRetryAuto = true;
          if (transaction.error !== "noCardPresent") {
            this.errorRetryCancel = false;
            if (this._errorRetryTimeout) {
              clearTimeout(this._errorRetryTimeout);
              this._errorRetryTimeout = null;
            }
          }
          if (!this._errorRetryTimeout && !this.errorRetryCancel) {
            this._errorRetryTimeout = setTimeout(() => {
              this.errorRetryCancel = true;
              this._errorRetryTimeout = null;
            }, 20000);
          }
          this.errorMessage = [
            {
              lang: "en",
              value: transaction.octopusErrorMessage[0],
            },
            {
              lang: "cht",
              value: transaction.octopusErrorMessage[1],
            },
            {
              lang: "chs",
              value: transaction.octopusErrorMessage[2],
            },
          ];
          return true;
        } else {
          const retry = new Promise<boolean | string>(resolve => {
            this.setError(
              [
                {
                  lang: "en",
                  value: transaction.octopusErrorMessage[0],
                },
                {
                  lang: "cht",
                  value: transaction.octopusErrorMessage[1],
                },
                {
                  lang: "chs",
                  value: transaction.octopusErrorMessage[2],
                },
              ],
              resolve,
            );
          });
          if (retry) {
            this.resetError();
          }
          return retry;
        }
      };

      if (checkID(this.currentPayment, payment)) {
        this.currentPaymentCancellable = true;
        transaction = await this.$paymentManager.octopus.transaction(
          input,
          String(payment._id),
          progress,
          retry as any,
          signal,
        );
      } else {
        if (!this.$paymentManager.octopus) {
          throw new Error(String(this.$t("octopus.notSetup")));
        }
        await this.$paymentManager.octopus.waitReady(true, this.$paymentManager.octopus.useNative ? 10000 : 30000);
        transaction = await this.$paymentManager.octopus.restoreTransaction(
          input,
          String(payment._id),
          progress,
          retry as any,
        );
      }
    } catch (e) {
      if (e?.type === "transactionError") {
        transaction = e;
      } else {
        errorMessage = e.message;
      }
    } finally {
      if (this._errorRetryTimeout) {
        clearTimeout(this._errorRetryTimeout);
        this._errorRetryTimeout = null;
      }
    }

    if (!transaction) {
      if (!transactionStarted) {
        payment = await this.$feathers.service("shop/payments/update").create({
          _id: payment._id,
          status: "error",
        });
      }
      if (errorMessage) {
        this.setError(errorMessage);
      } else {
        payment = await this.$feathers.service("shop/payments/update").create({
          _id: payment._id,
          status: "cancelled",
        });
      }
    } else if (transaction.type === "transactionError") {
      if (transaction.error === "noCardPresent") {
        payment = await this.$feathers.service("shop/payments/update").create({
          _id: payment._id,
          status: "cancelled",
        });
      } else {
        payment = await this.$feathers.service("shop/payments/update").create({
          _id: payment._id,
          status: "error",
          metadata: {
            errorMessage: transaction.errorMessage,
          },
          errorMessage: transaction.errorMessage,
        });
        if (checkID(this.currentPayment, payment)) {
          this.currentPayment = null;
        }
        if (transaction.octopusErrorMessage) {
          this.setError([
            {
              lang: "en",
              value: transaction.octopusErrorMessage[0],
            },
            {
              lang: "cht",
              value: transaction.octopusErrorMessage[1],
            },
            {
              lang: "chs",
              value: transaction.octopusErrorMessage[2],
            },
          ]);
        } else {
          this.setError(transaction.errorMessage);
        }
      }
    } else if (transaction.type === "transactionSuccess") {
      payment = await this.$feathers.service("shop/payments/update").create({
        _id: payment._id,
        status: "paid",
        metadata: {
          transaction: transaction,
          locId: this.$paymentManager.octopus.formatLocationId(transaction.locId || 0),
          devId: (transaction.devId || 0).toString(16).toUpperCase().padStart(6, "0"),
          txnId: (transaction.txnId || 0).toString(16).toUpperCase().padStart(4, "0"),
        },
      });
    }
    return payment;
  }

  async doTurnCloudPayment(payment: FindType<"shop/payments">) {
    if (!payment.metadata) {
      throw new Error("Invalid payment");
    }
    try {
      await this.$paymentManager.turnCloud.waitReady(true);
      const resp = await this.$paymentManager.turnCloud.doTransaction(payment.metadata);
      return await this.confirmTurnCloudPayment(payment, resp);
    } catch (e) {
      console.warn(e);
      if (e instanceof TurnCloudError) {
        this.$store.commit("SET_ERROR", e.message);
        return await this.confirmTurnCloudPayment(payment, e.payload);
      } else if (e instanceof TurnCloudConnectionError) {
        this.$store.commit("SET_ERROR", e.message);
        return await this.$feathers.service("shop/payments/update").create({
          _id: payment._id,
          status: "cancelled",
        });
      }
      throw e;
    }
  }

  async cancelTurnCloud(payment: FindType<"shop/payments">) {
    try {
      return await this.doTurnCloudPayment(payment);
    } catch (e) {
      return await this.$feathers.service("shop/payments/update").create({
        _id: payment._id,
        status: "error",
        errorMessage: e.message,
      });
    }
  }

  async confirmTurnCloudPayment(payment: FindType<"shop/payments">, resp: TurnCloudMessage) {
    if (resp.responseCode !== "orderSucess") {
      return await this.$feathers.service("shop/payments/update").create({
        _id: payment._id,
        status: "error",
        errorMessage: resp.responseCode,
        metadata: resp,
      });
    }
    return await this.confirmPayment(payment, resp, {
      amountInt: this.$pos.fromHumanNumberToRaw(resp.transAmount || 0),
    });
  }

  async cancelCurrentPayment() {
    if (this.cancelling) return;
    this.cancelling = true;
    try {
      const pendingPayments = this.session._id
        ? await this.$feathers.service("shop/payments").find({
            query: {
              $sort: { date: -1 },
              $paginate: false,
              $populate: ["method"],
              order: this.session._id,
              status: "pending",
            },
            paginate: false,
          } as const)
        : [];
      if (!pendingPayments.length) {
        this.currentPayment = null;
        return false;
      }
      for (let payment of pendingPayments) {
        let cancelPayment: typeof payment | boolean;
        if (payment.status === "pending") {
          switch (payment.method?.type) {
            case "bbpos": {
              cancelPayment = await this.cancelBBPOS(payment);
              break;
            }
            case "allinpay": {
              cancelPayment = await this.cancelAllinpayPOS(payment);
              break;
            }
            case "razer": {
              cancelPayment = (await this.cancelRazer(payment)) as any;
              break;
            }
            case "octopus": {
              cancelPayment = (await this.cancelOctopus(payment as any)) as any;
              break;
            }
            default: {
              const manager = this.$paymentManager.getMethod(payment.type);
              if (!manager) {
                throw new Error("Invalid payment method: " + payment.type);
              }
              await manager.waitReady(true);
              cancelPayment = (await this.doGenericPayment(manager, payment as any, undefined, true)) as any;
              break;
            }
          }
        }
        if (typeof cancelPayment === "boolean" || !cancelPayment) {
          if (!cancelPayment) {
            const c = await this.$openDialog(
              import("@feathers-client/components-internal/ConfirmDialog.vue"),
              {
                title: this.$t("payment.failedToCancel"),
              },
              {
                maxWidth: "400px",
              },
            );
            if (!c) return false;
            payment = (await this.$feathers.service("shop/payments").get(payment._id)) as any;
            if (payment.status !== "cancelled" && payment.status !== "refunded") {
              payment = (await this.$feathers.service("shop/payments/update").create({
                _id: payment._id,
                status: "cancelled",
              })) as any;
            }
          }
        } else {
          payment = cancelPayment;
        }

        if (payment.status === "paid") {
          await this.finishOrder(payment as any);
          if (this.session.paymentStatus === "paid") {
            return true;
          }
        } else {
          payment = (await this.$feathers.service("shop/payments/update").create({
            _id: payment._id,
            status: "cancelled",
          })) as any;
        }

        if (checkID(this.currentPayment, payment)) {
          this.currentPayment = null;
        }
      }
      return false;
    } finally {
      this.cancelling = false;
      if (this.paymentPromiseCancel) {
        this.paymentPromiseCancel.abort();
        this.paymentPromiseCancel = null;
      }
    }
  }

  async finishOrder(payment: PaymentType) {
    await this.session.reload();
    this.viewPayment = this.session?.payments?.at?.(-1) ?? null;

    let needOpenCash =
      (this.$pos.cashier.cashDrawer
        ? this.$pos.cashier.cashDrawerOpenType === "cash"
          ? this.currentPayment.type == "cash"
          : this.$pos.cashier.cashDrawerOpenType === "any"
          ? true
          : false
        : false) &&
      (this.currentPayment.amountInt > 0 || this.$pos.cashier.cashDrawerZeroOpen);

    if (
      ((this.session.status === "confirmed" || this.session.status === "done") && this.$pos.cashier.printOnFinish) ||
      this.$pos.cashier.printOnPartial
    ) {
      await this.printOrder(needOpenCash);
    } else if (needOpenCash) {
      await this.openCashBox();
    }
    if (
      this.session.item.twInvoice &&
      (this.session.item.twTaxType === "company" || this.session.item.twTaxType === "paper")
    ) {
      try {
        await this.session.printTwInvoice();
      } catch (e) {
        this.$store.commit("SET_ERROR", e.message);
      }
    }
  }

  printing = false;

  async printOrder(cashbox = false) {
    await this.session.printInvoice({
      cashbox,
    });
  }

  cancelOrder() {}

  getIconClass(method: PaymentMethodFlatten) {
    switch ((method.options?.method ?? method.type ?? "").toUpperCase()) {
      case "CASH":
      case "CREDITCARD":
      case "ALIPAY":
      case "WECHAT":
      case "QUICKPASS":
      case "OCTOPUS":
      case "POINT":
        return `$payment_${(method.options?.method ?? method.type ?? "").toUpperCase()}`;
      case "CREDIT":
        return `$payment_CREDITCARD`;
      default:
        return "$payment_OTHERS";
    }
  }

  async checkTaxStatus() {
    if (this.$config.features.turnCloud) {
      if (this.session.twTaxType !== "none") {
        if (!this.twTaxNumValid) {
          switch (this.session.twTaxType) {
            case "electronic":
              this.$store.commit("SET_ERROR", this.$t("turnCloud.taxElectNumError"));
              break;
            case "donate":
              this.$store.commit("SET_ERROR", this.$t("turnCloud.taxDonateNumError"));
              break;
            case "company":
              this.$store.commit("SET_ERROR", this.$t("turnCloud.taxCompanyNumError"));
              break;
            default:
              this.$store.commit("SET_ERROR", this.$t("turnCloud.taxNumError"));
              break;
          }
          return false;
        }
        const resp = await this.$feathers.service("shop/turnCloud/checkTaxStatus").create({});
        if (resp.invoiceStatus === "low") {
          await this.$openDialog(
            import("@feathers-client/components-internal/ConfirmDialog.vue"),
            {
              title: this.$t("turnCloud.taxStatusWarn", { remain: resp.remain }),
              hasCancel: false,
            },
            {
              maxWidth: "500px",
            },
          );
        } else if (resp.invoiceStatus === "no") {
          await this.$openDialog(
            import("@feathers-client/components-internal/ConfirmDialog.vue"),
            {
              title: this.$t("turnCloud.taxStatusError"),
              hasCancel: false,
            },
            {
              maxWidth: "500px",
            },
          );
          return false;
        }
      }
    }
    return true;
  }

  onScanner(info) {
    const { code, device, handled } = info;
    if (handled || this.loading || !code) return;
    if (this.$config.features.turnCloud) {
      if (/^\/[0-9A-Z\.\-\+]{7}$/.test(code)) {
        this.session.twTaxType = "electronic";
        this.session.twTaxVehicle = code;
      } else if (/^[A-Z]{2}[0-9]{14}$/.test(code)) {
        this.session.twTaxType = "electronic";
        this.session.twTaxVehicle = code;
      } else if (/^[a-zA-Z0-9]{10}$/.test(code) && this.session.twTaxType === "nrt") {
        this.session.twDonate = code;
      }
    }
  }
  get twTaxNumValid() {
    let canPay = true;
    if (this.session.twTaxType === "electronic") {
      if (
        /^\/[0-9A-Z\.\-\+]{7}$/.test(this.session.twTaxVehicle) ||
        /^[A-Z]{2}[0-9]{14}$/.test(this.session.twTaxVehicle)
      ) {
        canPay = true;
      } else {
        canPay = false;
      }
    } else if (this.session.twTaxType === "company") {
      if (/^[0-9]{8}$/.test(this.session["twTaxComp"])) {
        canPay = true;
      } else {
        canPay = false;
      }
    } else if (this.session.twTaxType === "donate") {
      if (/^[0-9]+$/.test(this.session["twDonate"])) {
        canPay = true;
      } else {
        canPay = false;
      }
    } else if (this.session.twTaxType === "nrt") {
      if (/^[a-zA-Z0-9]{10}$/.test(this.session["twTaxNrt"])) {
        canPay = true;
      } else {
        canPay = false;
      }
    }
    return canPay;
  }

  get networks() {
    return this.currentPayment?.type === "bbpos"
      ? [
          { _id: "visa", name: "Visa" },
          { _id: "master", name: "Master" },
          { _id: "jcb", name: "JCB" },
          { _id: "amex", name: "Amex" },
          { _id: "alipay", name: "Alipay" },
          { _id: "wechat", name: "Wechat" },
        ]
      : null;
  }

  async retryPayment() {
    try {
      this.cancelling = true;
      this.resetError();
      // await this.cancelOnePayment(this.currentPayment, true);
    } catch (e) {
      this.setError(e.message);
    } finally {
      this.cancelling = false;
    }
  }

  _errorRetryTimeout: any;

  statusMessage: string | LangArrType = null;
  errorMessage: string | LangArrType = null;
  errorRetry: (retry?: boolean | string) => Promise<void> | void = null;
  errorButtons: string[] = null;
  errorRetryCancel = true;
  errorRetryCalled = false;
  errorRetryAuto = false;
  manualConfirming: FindType<"shop/payments"> = null;
  manualConfirmRef: string = "";
  manualConfirmNetwork: string = "";
  manualConfirmType: "confirm" | "cancel" = "confirm";

  currentPaymentCancellable = false;
  status:
    | "pending"
    | "paying"
    | "retrying"
    | "restoring"
    | "error"
    | "errorConnect"
    | "done"
    | "recovery"
    | "scanning" = "pending";

  cancelManualConfirm() {
    this.manualConfirming = null;
    if (this.manualConfirmCb) {
      this.manualConfirmCb(null);
      this.manualConfirmCb = null;
    }
  }

  setError(
    error: string | LangArrType,
    retry?: (retry?: boolean | string) => Promise<void> | void,
    cancelDelay?: number,
    buttons?: string[],
  ) {
    this.status = "error";
    this.errorMessage = error;
    this.errorRetry = retry;
    this.errorRetryAuto = false;
    this.errorButtons = buttons;
    this.errorRetryCalled = false;
    if (this._errorRetryTimeout) {
      clearTimeout(this._errorRetryTimeout);
      this._errorRetryTimeout = null;
    }
    if (cancelDelay) {
      this.errorRetryCancel = false;
      this._errorRetryTimeout = setTimeout(() => {
        this._errorRetryTimeout = null;
        this.errorRetryCancel = true;
      }, cancelDelay);
    } else {
      this.errorRetryCancel = true;
    }
  }

  async errorConfirmRetry(retry: boolean | string) {
    try {
      if (!this.errorRetry) {
        this.resetError();
      } else {
        await this.errorRetry(retry);
        this.errorRetryCalled = true;
      }
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async continueRecovery(doRecovery: boolean) {
    if (doRecovery) {
      await this.cancelCurrentPayment();
    } else {
      this.status = "pending";
    }
  }

  get manualConfirmTypes() {
    return [
      {
        _id: "confirm",
        name: { $t: "paymentDialog.manualConfirm" },
      },
      {
        _id: "cancel",
        name: { $t: "paymentDialog.manualCancel" },
      },
    ];
  }

  resetDialog() {
    this.loading = false;
    this.status = "pending";
    this.resetError();
  }

  resetError() {
    this.errorMessage = null;
    if (!this.errorRetryCalled) {
      this.errorRetry?.(false);
    }
    if (this._errorRetryTimeout) {
      clearTimeout(this._errorRetryTimeout);
      this._errorRetryTimeout = null;
    }
    this.errorRetryAuto = false;
    this.errorRetry = null;
    this.errorButtons = null;
    this.errorRetryCalled = false;
    this.manualConfirming = null;
    if (this.manualConfirmCb) {
      this.manualConfirmCb(null);
      this.manualConfirmCb = null;
    }
    this.manualConfirmType = "confirm";
    this.statusMessage = null;
  }

  manualConfirmCb: (confirm: FindType<"shop/payments">) => void;

  doManualConfirm(payment: FindType<"shop/payments">) {
    if (this.manualConfirmCb) {
      this.manualConfirmCb(null);
      this.manualConfirmCb = null;
    }
    this.manualConfirming = payment;
    this.manualConfirmRef = this.currentPayment?.tranID ?? "";
    return new Promise<FindType<"shop/payments">>(resolve => {
      this.manualConfirmCb = resolve;
    });
  }

  scannerTask: { resolve: (value: string) => void; reject: (reason?: any) => void } = null;
  showManualScanner = false;
  paymentImage: string = null;

  async scanCode(): Promise<string> {
    if (this.status === "scanning") {
      throw new Error("Already scanning");
    }
    const cancellable = this.currentPaymentCancellable;
    this.currentPaymentCancellable = true;
    const status = this.status;
    this.status = "scanning";
    this.showManualScanner = false;
    try {
      return await new Promise<string>((resolve, reject) => {
        this.scannerTask = { resolve, reject };
      });
    } finally {
      if (this.status === "scanning") {
        this.status = status;
      }
      this.currentPaymentCancellable = cancellable;
      this.scannerTask = null;
      this.showManualScanner = false;
    }
  }

  get showDialog() {
    return this.loading || this.cancelling || !!this.errorMessage || this.status === "recovery";
  }

  get serialNo() {
    return this.currentPayment?.type === "bbpos" ? this.$paymentManager.bbmsl?.serialNo : "";
  }

  async manualConfirm() {
    if (this.manualConfirmType === "confirm") {
      const payment = await this.$feathers.service("shop/payments/update").create({
        _id: this.manualConfirming._id,
        status: "paid",
        tranID: this.manualConfirmRef,
        ...(this.manualConfirmNetwork
          ? {
              methodNetwork: this.manualConfirmNetwork,
            }
          : {}),
      });
      if (checkID(payment, this.currentPayment)) {
        this.currentPayment = payment;
      }
      await this.finishOrder(payment);
      this.manualConfirming = null;
      if (this.manualConfirmCb) {
        this.manualConfirmCb(payment);
        this.manualConfirmCb = null;
      }
      return payment;
    } else {
      const payment = await this.$feathers.service("shop/payments/update").create({
        _id: this.manualConfirming._id,
        status: "cancelled",
        ...(this.manualConfirmRef
          ? {
              refId: this.manualConfirmRef,
            }
          : {}),
        ...(this.manualConfirmNetwork
          ? {
              network: this.manualConfirmNetwork,
            }
          : {}),
      });
      if (checkID(payment, this.currentPayment)) {
        this.currentPayment = null;
        this.manualConfirming = null;
        this.resetError();
      }
      if (this.manualConfirmCb) {
        this.manualConfirmCb(payment);
        this.manualConfirmCb = null;
      }
      return payment;
    }
  }

  get paymentComponent() {
    switch (this.paymentMethod?.type) {
      case "octopus":
        return Octopus;
    }
  }
}
