
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import Cart from "./cart.vue";
import { Order } from "@common/pos";
import { checkID, FindPopRawType, FindType } from "@feathers-client";
import type { CategoryType, BrandType } from "@common/common";
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";
import { watch } from "less";

interface ListPage {
  _id: string;
  name: any;
  type: "shop/product/searches" | "shop/categories" | "shop/brands";
  component: string;
  query: any;
}

@Component
export default class OrderSystemKioskPicker extends mixins(Cart) {
  @Prop()
  session: Order;

  productComments: FindPopRawType<["user"], "shop/product/comments">[] = [];
  productCommentSummaries: FindType<"shop/product/comments/summary">[] = [];

  get shopIcon() {
    return (this.$shop?.icon && (this.$imageSet(this.$shop.icon) as any)?.src) || require("~/assets/images/logo.png");
  }

  get products() {
    if (this.session.viewingMode === "product") {
      return this.session.viewingItems;
    } else {
      return this.session.viewingSkus;
    }
  }

  get items() {
    return this.products.map(it => ({
      ...it,
      image: it.mainImage || it.images?.[0],
      tag: this.productGroups[0]?.tags.map(it => ({ color: it.color, text: this.$td(it.name) })),
      sku: it._id,
      productTitle: this.$td(it.displayName || it.name),
      originalPrice: this.productGroups[0]?.originalPrice,
      price: this.productGroups[0]?.price,
      amountSold: this.productGroups[0]?.cachedSales,
      rating: this.productCommentSummaries[0]?.average,
      ratingNumber: this.productCommentSummaries[0]?.total,
      comments: this.productComments,
      linkedProduct: it,
    }));
  }

  productGroups: FindType<"shop/product/searches">[] = [];

  close() {
    this.resetViewing();
  }

  async openProductDialog(item) {
    try {
      this.$pos.analyticsManager.logOrderEvent("openProductDetails", this.session._id, {
        sku: item.linkedProduct,
      });

      const relatedAd = await this.$feathers.service("shop/ads").find({
        query: {
          $or: [
            {
              cashiers: { $in: [this.$pos.cashier?._id] },
            },
            {
              cashiers: { $exists: false },
            },
            {
              cashiers: [],
            },
          ],
          type: "relatedZone",
          linkedProductSku: item?.sku || item?._id,
          $paginate: false,
          status: "published",
          $sort: { date: -1 },
        },
        paginate: false,
      });

      await this.$openDialog("hkpc-product-ad-detail-card", {
        productInfo: item,
      });

      if (relatedAd.length && relatedAd[0]) {
        setTimeout(() => {
          this.$emit("playAd", relatedAd[0]);
        }, relatedAd[0].delayTimer * 1000);
      }
    } catch (e) {
      console.error(e);
    }
  }

  getProductPrice(productSku) {
    if (!productSku.shopTable) {
      return 0;
    } // should be return latest pos price instead of 0

    const firstShopTable = productSku.shopTable.find(el => this.$shop._id === el.shop?._id || el);
    const erpPricing = firstShopTable?.pricing.find(el => el.tag === "erp");
    if (erpPricing) {
      return erpPricing.priceInt ?? erpPricing.price ?? 0;
    }
    return firstShopTable?.pricing[0]?.priceInt ?? firstShopTable?.pricing[0]?.price ?? 0; // should be return latest pos price instead of 0
  }

  thumb(item) {
    return (item as any).mainImage
      ? this.$thumb((item as any).mainImage)
      : (item?.images?.[0] && this.$thumb(item.images[0])) || require("~/assets/images/logo.png");
  }

  async select(item) {
    // this.$emit('select', item)
    console.log(item);
    if (item) {
      if (this.session.viewingMode === "product") {
        this.$emit("addToCart", item);
      } else {
        await this.session.addProduct(
          {
            sku: item,
          },
          {
            quantity: 1,
          },
        );
      }
    }
  }

  @Watch("products")
  async fetchProductInfo() {
    try {
      this.productComments = await this.$feathers.service("shop/product/comments").find({
        query: {
          [this.session.viewingMode === "product" ? "product" : "sku"]: this.products.map(el => el._id),
          $populate: [{ path: "user", model: "ShopUser" }],
          $sort: {
            createdAt: -1,
          },
          $paginate: false,
        },

        paginate: false,
      });
    } catch (e) {
      console.error(e);
    }
    try {
      this.productCommentSummaries = await Promise.all(
        this.products.map(el => {
          return this.$feathers.service("shop/product/comments/summary").create({
            [this.session.viewingMode === "product" ? "product" : "sku"]: el._id,
            shop: `${this.$shop._id}`,
          });
        }),
      );
    } catch (e) {
      console.error(e);
    }

    try {
      this.productGroups = await Promise.all(
        this.products.map(async el => {
          return (
            await this.$feathers
              .service("shop/product/searches")
              .find({ query: { productGroup: el.productGroup, $paginate: false }, paginate: false })
          )?.[0];
        }),
      );
    } catch (e) {
      console.error(e);
    }
  }

  mounted() {
    this.fetchProductInfo();
  }
}
