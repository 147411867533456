export { default as AsyncPopulate } from '../../dep/feathers-client/components/AsyncPopulate.vue'
export { default as BackToTopBtn } from '../../dep/feathers-client/components/BackToTopBtn.vue'
export { default as BoxsAppContainer } from '../../dep/feathers-client/components/BoxsAppContainer.vue'
export { default as BoxsProfileBtn } from '../../dep/feathers-client/components/BoxsProfileBtn.vue'
export { default as DialogHost } from '../../dep/feathers-client/components/DialogHost.vue'
export { default as DropDownMenu } from '../../dep/feathers-client/components/DropDownMenu.vue'
export { default as FullscreenHost } from '../../dep/feathers-client/components/FullscreenHost.vue'
export { default as HCard } from '../../dep/feathers-client/components/HCard.vue'
export { default as IdleDialog } from '../../dep/feathers-client/components/IdleDialog.vue'
export { default as ItemPicker } from '../../dep/feathers-client/components/ItemPicker.vue'
export { default as ItemPickerNew } from '../../dep/feathers-client/components/ItemPickerNew.vue'
export { default as LazyRender } from '../../dep/feathers-client/components/LazyRender.vue'
export { default as ListTree } from '../../dep/feathers-client/components/ListTree.vue'
export { default as MenuList } from '../../dep/feathers-client/components/MenuList.vue'
export { default as MenuListButton } from '../../dep/feathers-client/components/MenuListButton.vue'
export { default as MenuListCheckbox } from '../../dep/feathers-client/components/MenuListCheckbox.vue'
export { default as MenuListInput } from '../../dep/feathers-client/components/MenuListInput.vue'
export { default as MenuListItem } from '../../dep/feathers-client/components/MenuListItem.vue'
export { default as MenuListNum } from '../../dep/feathers-client/components/MenuListNum.vue'
export { default as MenuListNumOnClick } from '../../dep/feathers-client/components/MenuListNumOnClick.vue'
export { default as MenuListPicker } from '../../dep/feathers-client/components/MenuListPicker.vue'
export { default as MenuListPickerDropdown } from '../../dep/feathers-client/components/MenuListPickerDropdown.vue'
export { default as MenuListPickerItem } from '../../dep/feathers-client/components/MenuListPickerItem.vue'
export { default as MenuListSwitch } from '../../dep/feathers-client/components/MenuListSwitch.vue'
export { default as MultiLangText } from '../../dep/feathers-client/components/MultiLangText.vue'
export { default as NestedRouter } from '../../dep/feathers-client/components/NestedRouter.vue'
export { default as NumPad } from '../../dep/feathers-client/components/NumPad.vue'
export { default as NumPadNew } from '../../dep/feathers-client/components/NumPadNew.vue'
export { default as NumPadSimply } from '../../dep/feathers-client/components/NumPadSimply.vue'
export { default as ResizeSensor } from '../../dep/feathers-client/components/ResizeSensor.vue'
export { default as SlotRender } from '../../dep/feathers-client/components/SlotRender.vue'
export { default as StaticRender } from '../../dep/feathers-client/components/StaticRender.vue'
export { default as StatusHost } from '../../dep/feathers-client/components/StatusHost.vue'
export { default as SwipeAction } from '../../dep/feathers-client/components/SwipeAction.vue'
export { default as TabPicker } from '../../dep/feathers-client/components/TabPicker.vue'
export { default as TeleportMenu } from '../../dep/feathers-client/components/TeleportMenu.vue'
export { default as Highlighter } from '../../dep/feathers-client/components/highlighter.vue'
export { default as BoxsNavBarItem } from '../../dep/feathers-client/components/BoxsNavBar/Item.vue'
export { default as BoxsNavBar } from '../../dep/feathers-client/components/BoxsNavBar/index.vue'
export { default as BoxsNavBarNewNavItem } from '../../dep/feathers-client/components/BoxsNavBar/newNavItem.vue'
export { default as BBtn } from '../../components/BBtn.vue'
export { default as CustomDateRangePicker } from '../../components/CustomDateRangePicker.vue'
export { default as DateButtonGroup } from '../../components/DateButtonGroup.vue'
export { default as DatePicker } from '../../components/DatePicker.vue'
export { default as DraggableSimpleList } from '../../components/DraggableSimpleList.vue'
export { default as FilePicker } from '../../components/FilePicker.vue'
export { default as ImagePicker } from '../../components/ImagePicker.vue'
export { default as KioskStatusHost } from '../../components/KioskStatusHost.vue'
export { default as LabelDialog } from '../../components/LabelDialog.vue'
export { default as LoginMenu } from '../../components/LoginMenu.vue'
export { default as MediaLibrary } from '../../components/MediaLibrary.vue'
export { default as NetworkConnectDialog } from '../../components/NetworkConnectDialog.vue'
export { default as ObjectPicker } from '../../components/ObjectPicker.vue'
export { default as OrderListTable } from '../../components/OrderListTable.vue'
export { default as OrderManager } from '../../components/OrderManager.vue'
export { default as Overview } from '../../components/Overview.vue'
export { default as PaymentButton } from '../../components/PaymentButton.vue'
export { default as Percentage } from '../../components/Percentage.vue'
export { default as PointManager } from '../../components/PointManager.vue'
export { default as PopularProduct } from '../../components/PopularProduct.vue'
export { default as PopularProductPercentage } from '../../components/PopularProductPercentage.vue'
export { default as PriceRow } from '../../components/PriceRow.vue'
export { default as ProductPreviewSvgInstructions } from '../../components/ProductPreviewSvgInstructions.vue'
export { default as QrCode } from '../../components/QrCode.vue'
export { default as SimpleList } from '../../components/SimpleList.vue'
export { default as TelCodeSelector } from '../../components/TelCodeSelector.vue'
export { default as TranslateBox } from '../../components/TranslateBox.vue'
export { default as TranslateImagePicker } from '../../components/TranslateImagePicker.vue'
export { default as Formatter } from '../../components/formatter.js'
export { default as NumCounter } from '../../components/numCounter.vue'
export { default as StatusPicker } from '../../components/statusPicker.vue'
export { default as BoxsAclEditor } from '../../components/boxs/AclEditor.vue'
export { default as BoxsAddPointDialog } from '../../components/boxs/AddPointDialog.vue'
export { default as BoxsAddressEditor } from '../../components/boxs/AddressEditor.vue'
export { default as BoxsArticleTypePicker } from '../../components/boxs/ArticleTypePicker.vue'
export { default as BoxsBatchProductEditDialog } from '../../components/boxs/BatchProductEditDialog.vue'
export { default as BoxsBatchProductStatusDialog } from '../../components/boxs/BatchProductStatusDialog.vue'
export { default as BoxsCategoryPicker } from '../../components/boxs/CategoryPicker.vue'
export { default as BoxsContactFieldEditor } from '../../components/boxs/ContactFieldEditor.vue'
export { default as BoxsContactFieldValue } from '../../components/boxs/ContactFieldValue.vue'
export { default as BoxsCustomerListDialog } from '../../components/boxs/CustomerListDialog.vue'
export { default as BoxsEditSeparateProductTable } from '../../components/boxs/EditSeparateProductTable.vue'
export { default as BoxsInventoryManagement } from '../../components/boxs/InventoryManagement.vue'
export { default as BoxsMobilePageView } from '../../components/boxs/MobilePageView.vue'
export { default as BoxsNavTitle } from '../../components/boxs/NavTitle.vue'
export { default as BoxsNumberCalc } from '../../components/boxs/NumberCalc.vue'
export { default as BoxsOrderPopup } from '../../components/boxs/OrderPopup.vue'
export { default as BoxsPageHistory } from '../../components/boxs/PageHistory.vue'
export { default as BoxsPaymentInstructionEditor } from '../../components/boxs/PaymentInstructionEditor.vue'
export { default as BoxsPaymentOptionEditor } from '../../components/boxs/PaymentOptionEditor.vue'
export { default as BoxsPaymentSecretEditor } from '../../components/boxs/PaymentSecretEditor.vue'
export { default as BoxsPosItemDetails } from '../../components/boxs/PosItemDetails.vue'
export { default as BoxsPriceLineName } from '../../components/boxs/PriceLineName.vue'
export { default as BoxsProductPricingCell } from '../../components/boxs/ProductPricingCell.vue'
export { default as BoxsProductStatusCell } from '../../components/boxs/ProductStatusCell.vue'
export { default as BoxsShipmentContainer } from '../../components/boxs/ShipmentContainer.vue'
export { default as BoxsShipmentOrder } from '../../components/boxs/ShipmentOrder.vue'
export { default as BoxsShipmentScheduleCard } from '../../components/boxs/ShipmentScheduleCard.vue'
export { default as BoxsShipmentScheduleFilter } from '../../components/boxs/ShipmentScheduleFilter.vue'
export { default as BoxsShipmentScheduleLabelDialog } from '../../components/boxs/ShipmentScheduleLabelDialog.vue'
export { default as BoxsShipmentScheduleOrderAssignmentDialog } from '../../components/boxs/ShipmentScheduleOrderAssignmentDialog.vue'
export { default as BoxsShipmentScheduleOrderCancelDialog } from '../../components/boxs/ShipmentScheduleOrderCancelDialog.vue'
export { default as BoxsShipmentScheduleOrderDialogItem } from '../../components/boxs/ShipmentScheduleOrderDialogItem.vue'
export { default as BoxsShipmentScheduleOrderManager } from '../../components/boxs/ShipmentScheduleOrderManager.vue'
export { default as BoxsShipmentScheduleOrderMergeDialog } from '../../components/boxs/ShipmentScheduleOrderMergeDialog.vue'
export { default as BoxsShipmentScheduleOrderSplitDialog } from '../../components/boxs/ShipmentScheduleOrderSplitDialog.vue'
export { default as BoxsShipmentScheduleShipmentDialog } from '../../components/boxs/ShipmentScheduleShipmentDialog.vue'
export { default as BoxsShipmentScheduleShipmentManager } from '../../components/boxs/ShipmentScheduleShipmentManager.vue'
export { default as BoxsShipmentScheduleShipmentStatusDialog } from '../../components/boxs/ShipmentScheduleShipmentStatusDialog.vue'
export { default as BoxsShipmentScheduleStatusDialog } from '../../components/boxs/ShipmentScheduleStatusDialog.vue'
export { default as BoxsShipmentScheduleSummary } from '../../components/boxs/ShipmentScheduleSummary.vue'
export { default as BoxsSingleCurrencyEditor } from '../../components/boxs/SingleCurrencyEditor.vue'
export { default as BoxsSpecEditor } from '../../components/boxs/SpecEditor.vue'
export { default as BoxsSpecSearch } from '../../components/boxs/SpecSearch.vue'
export { default as BoxsSpecValue } from '../../components/boxs/SpecValue.vue'
export { default as BoxsSpecValueView } from '../../components/boxs/SpecValueView.vue'
export { default as ChartsNewDoughnutChart } from '../../components/charts/NewDoughnutChart.vue'
export { default as ChartsNewLineChart } from '../../components/charts/NewLineChart.vue'
export { default as ChartsMonthlyIncome } from '../../components/charts/monthlyIncome.vue'
export { default as ChartsPercentChart } from '../../components/charts/percentChart.vue'
export { default as ChartsReactive } from '../../components/charts/reactive.js'
export { default as ChartsSalesByCategory } from '../../components/charts/salesByCategory.vue'
export { default as DialogsAdjustFont } from '../../components/dialogs/AdjustFont.vue'
export { default as DialogsBluetoothSelector } from '../../components/dialogs/BluetoothSelector.vue'
export { default as DialogsCancelConfirmDialog } from '../../components/dialogs/CancelConfirmDialog.vue'
export { default as ErpDataTableForErp } from '../../components/erp/DataTableForErp.vue'
export { default as ErpIdList } from '../../components/erp/ErpIdList.vue'
export { default as ErpExcelExporter } from '../../components/erp/ExcelExporter.ts'
export { default as ErpMonthlyStmtForm } from '../../components/erp/MonthlyStmtForm.vue'
export { default as ErpWholesaleOrderForm } from '../../components/erp/WholesaleOrderForm.vue'
export { default as HkpcAdPlayer } from '../../components/hkpc/AdPlayer.vue'
export { default as HkpcCommentFilter } from '../../components/hkpc/CommentFilter.vue'
export { default as HkpcProductAdDetailCard } from '../../components/hkpc/ProductAdDetailCard.vue'
export { default as HkpcProductCard } from '../../components/hkpc/ProductCard.vue'
export { default as HkpcProductInputCard } from '../../components/hkpc/ProductInputCard.vue'
export { default as HkpcReviewDialog } from '../../components/hkpc/ReviewDialog.vue'
export { default as DialogsPosCashCheckDialog } from '../../components/dialogs/pos/CashCheckDialog.vue'
export { default as DialogsPosCashierSetup } from '../../components/dialogs/pos/CashierSetup.vue'
export { default as DialogsPosCashierSetupDialog } from '../../components/dialogs/pos/CashierSetupDialog.vue'
export { default as DialogsPosDiscountAddonCategory } from '../../components/dialogs/pos/DiscountAddonCategory.vue'
export { default as DialogsPosDiscountAddonMember } from '../../components/dialogs/pos/DiscountAddonMember.vue'
export { default as DialogsPosDiscountAddonProduct } from '../../components/dialogs/pos/DiscountAddonProduct.vue'
export { default as DialogsPosOrderIdList } from '../../components/dialogs/pos/OrderIdList.vue'
export { default as DialogsPosProductBundleSelector } from '../../components/dialogs/pos/ProductBundleSelector.vue'
export { default as DialogsPosProductSelector } from '../../components/dialogs/pos/ProductSelector.vue'
export { default as DialogsPosProductWeight } from '../../components/dialogs/pos/ProductWeight.vue'
export { default as DialogsPosSkuSelector } from '../../components/dialogs/pos/SkuSelector.vue'
export { default as DialogsPosOrderReview } from '../../components/dialogs/pos/orderReview.vue'
export { default as ErpDialogsAlertDialog } from '../../components/erp/dialogs/AlertDialog.vue'
export { default as ErpDialogsCancelConfirmDialog } from '../../components/erp/dialogs/CancelConfirmDialog.vue'
export { default as ErpDialogsCustomProduct } from '../../components/erp/dialogs/CustomProduct.vue'
export { default as ErpDialogsDeliveryNoteListDialog } from '../../components/erp/dialogs/DeliveryNoteListDialog.vue'
export { default as ErpDialogsDetailsViewer } from '../../components/erp/dialogs/DetailsViewer.vue'
export { default as ErpDialogsDocUploaderDialog } from '../../components/erp/dialogs/DocUploaderDialog.vue'
export { default as ErpDialogsEditCartDiscount } from '../../components/erp/dialogs/EditCartDiscount.vue'
export { default as ErpConfirmDialog } from '../../components/erp/dialogs/ErpConfirmDialog.vue'
export { default as ErpDialogsInventoryEntryDialog } from '../../components/erp/dialogs/InventoryEntryDialog.vue'
export { default as ErpDialogsInvoicePickerDialog } from '../../components/erp/dialogs/InvoicePickerDialog.vue'
export { default as ErpDialogsPaymentDialog } from '../../components/erp/dialogs/PaymentDialog.vue'
export { default as ErpDialogsProductsPickerDialog } from '../../components/erp/dialogs/ProductsPickerDialog.vue'
export { default as ErpDialogsShipmentDialog } from '../../components/erp/dialogs/ShipmentDialog.vue'
export { default as ErpDialogsWholesaleOrderPickerDialog } from '../../components/erp/dialogs/WholesaleOrderPickerDialog.vue'
export { default as ErpDialogsInvoiceDialog } from '../../components/erp/dialogs/invoiceDialog.vue'
export { default as TableOrderSystemCalendar } from '../../components/table/orderSystem/calendar.vue'
export { default as TableOrderSystemCart } from '../../components/table/orderSystem/cart.vue'
export { default as TableOrderSystemCartList } from '../../components/table/orderSystem/cartList.vue'
export { default as TableOrderSystemCartPanel } from '../../components/table/orderSystem/cartPanel.vue'
export { default as TableOrderSystemCartPriceDetails } from '../../components/table/orderSystem/cartPriceDetails.vue'
export { default as TableOrderSystemCash } from '../../components/table/orderSystem/cash.vue'
export { default as TableOrderSystemCat } from '../../components/table/orderSystem/cat.vue'
export { default as TableOrderSystemCheckout } from '../../components/table/orderSystem/checkout.vue'
export { default as TableOrderSystemDiscount } from '../../components/table/orderSystem/discount.vue'
export { default as TableOrderSystemDiscountLine } from '../../components/table/orderSystem/discountLine.vue'
export { default as TableOrderSystemFinishOrder } from '../../components/table/orderSystem/finishOrder.vue'
export { default as TableOrderSystemHeader } from '../../components/table/orderSystem/header.vue'
export { default as TableOrderSystemHeaderPayment } from '../../components/table/orderSystem/headerPayment.vue'
export { default as TableOrderSystemKiosk } from '../../components/table/orderSystem/kiosk.vue'
export { default as TableOrderSystemKioskIdle } from '../../components/table/orderSystem/kioskIdle.vue'
export { default as TableOrderSystemKioskPasswordDialog } from '../../components/table/orderSystem/kioskPasswordDialog.vue'
export { default as TableOrderSystemKioskPicker } from '../../components/table/orderSystem/kioskPicker.vue'
export { default as TableOrderSystemManualAdjustDialog } from '../../components/table/orderSystem/manualAdjustDialog.vue'
export { default as TableOrderSystemMember } from '../../components/table/orderSystem/member.vue'
export { default as TableOrderSystemNumPadDialog } from '../../components/table/orderSystem/numPadDialog.vue'
export { default as TableOrderSystemPayPoint } from '../../components/table/orderSystem/payPoint.vue'
export { default as TableOrderSystemPaymentAmount } from '../../components/table/orderSystem/paymentAmount.vue'
export { default as TableOrderSystemPaymentRecord } from '../../components/table/orderSystem/paymentRecord.vue'
export { default as TableOrderSystemPeople } from '../../components/table/orderSystem/people.vue'
export { default as TableOrderSystemPicker } from '../../components/table/orderSystem/picker.vue'
export { default as TableOrderSystemPoint } from '../../components/table/orderSystem/point.vue'
export { default as TableOrderSystemPointItem } from '../../components/table/orderSystem/pointItem.vue'
export { default as TableOrderSystemProduct } from '../../components/table/orderSystem/product.vue'
export { default as TableOrderSystemProductKiosk } from '../../components/table/orderSystem/productKiosk.vue'
export { default as TableOrderSystemProductLine } from '../../components/table/orderSystem/productLine.vue'
export { default as TableOrderSystemProductOption } from '../../components/table/orderSystem/productOption.vue'
export { default as TableOrderSystemSetting } from '../../components/table/orderSystem/setting.vue'
export { default as TableOrderSystemStaff } from '../../components/table/orderSystem/staff.vue'
export { default as TableOrderSystemStaffPicker } from '../../components/table/orderSystem/staffPicker.vue'
export { default as TableDatePicker } from '../../components/table/orderSystem/tableDatePicker.vue'
export { default as TableOrderSystemTax } from '../../components/table/orderSystem/tax.vue'
export { default as TableOrderSystemTwTax } from '../../components/table/orderSystem/twTax.vue'
export { default as TableOrderSystemHistory } from '../../components/table/orderSystem/history/index.vue'
export { default as TableOrderSystemHistoryItem } from '../../components/table/orderSystem/history/item.vue'
export { default as TableOrderSystemSessionSummary } from '../../components/table/orderSystem/session/summary.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
