import { render, staticRenderFns } from "./ColorPicker.vue?vue&type=template&id=db4c5892&scoped=true"
import script from "./ColorPicker.vue?vue&type=script&lang=ts"
export * from "./ColorPicker.vue?vue&type=script&lang=ts"
import style0 from "./ColorPicker.vue?vue&type=style&index=0&id=db4c5892&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db4c5892",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
installComponents(component, {VColorPicker})


/* hot reload */
if (module.hot) {
  var api = require("/Users/kelson/Desktop/projects/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('db4c5892')) {
      api.createRecord('db4c5892', component.options)
    } else {
      api.reload('db4c5892', component.options)
    }
    module.hot.accept("./ColorPicker.vue?vue&type=template&id=db4c5892&scoped=true", function () {
      api.rerender('db4c5892', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "dep/site-editor/ColorPicker.vue"
export default component.exports