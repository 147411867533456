import { render, staticRenderFns } from "./DataTableForErp.vue?vue&type=template&id=6f798e87"
import script from "./DataTableForErp.vue?vue&type=script&lang=ts"
export * from "./DataTableForErp.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kelson/Desktop/projects/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f798e87')) {
      api.createRecord('6f798e87', component.options)
    } else {
      api.reload('6f798e87', component.options)
    }
    module.hot.accept("./DataTableForErp.vue?vue&type=template&id=6f798e87", function () {
      api.rerender('6f798e87', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/erp/DataTableForErp.vue"
export default component.exports