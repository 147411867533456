
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, FindType } from "@feathers-client";
import Cart from "./cart.vue";
import moment from "moment";
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";
import { playAudioBuffer, loadAudioFile, StopHandle } from "@feathers-client/qrcode-scanner/beep";
// @ts-ignore
import AUDIO_FILE from "!file-loader!~/assets/audio/alert.mp3";

@Component({
  components: {
    QrcodeStream,
  },
})
export default class OrderSystemKiosk extends mixins(Cart) {
  bannerIndex = 0;
  banners: FindType<"shop/pos/banners">[] = [];
  paymentAmountInt = 0;
  payLoading = false;
  canPay = false;
  scanning = false;

  lang = false;

  isLogin = false;

  infoToggle() {
    this.$store.commit("SET_ERROR", {
      message: this.$t("hkpc.helpMessage"),
      timeout: 30000,
    });
  }

  logout() {
    this.session.setUser(null);
  }

  phone = "";
  searchKeyword = "";
  selectAll() {
    this.search?.select?.();
  }

  setError(e) {
    console.log(e);
    this.$store.commit("SET_ERROR", e);
  }

  @Ref()
  search: HTMLInputElement;

  goSearch() {
    this.search?.blur?.();
    if (this.searchKeyword) {
      const code = `${this.searchKeyword || ""}`.trim();
      this.handleProductCode(code);
      // this.$emit("search", this.searchKeyword);
    }
  }

  get loggedIn() {
    return !!this.session.user;
  }

  async login(e) {
    if (e) {
      this.isLogin = false;
      this.session.setUser(e);

      this.$pos.analyticsManager.logOrderEvent("login", this.session._id, { user: e });

      this.session.saveCart();
    }
  }

  confirm() {
    // this.modalResult((this.$pos.cashier.kioskPassword || "0000") === this.password);
  }

  @Ref()
  page: any;

  async mounted() {
    this.banners = await this.$feathers.service("shop/pos/banners").find({
      query: {
        $paginate: false,
        publish: {
          $lt: moment().toDate(),
        },
        expiry: {
          $gt: moment().toDate(),
        },
        type: "kioskTop",
        status: "published",
        $sort: {
          publish: 1,
        },
      },
      paginate: false,
    });
  }

  beforeDestroy() {
    if (this.alertTask) {
      this.alertTask.stop();
      this.alertTask = null;
    }
  }

  async doPay() {
    this.scanning = false;
    const result = await this.page.navigateAndPay();
    // if(result){
    //   await this.$openDialog(
    //     import("~/components/hkpc/ReviewDialog.vue"),
    //     {
    //       session: this.session,
    //     },
    //     {
    //       maxWidth: "50dvw",
    //     },
    //   );
    // }
    return result;
  }

  async doSearch() {
    await this.$openDialog(
      import("~/components/hkpc/ProductInputCard.vue"),
      {
        session: this.session,
      },
      {
        maxWidth: "90dvw",
      },
    );
  }

  cancellingPay = false;

  @Watch("payLoading")
  onPayLoading() {
    this.cancellingPay = false;
  }

  async cancelPay() {
    this.cancellingPay = true;
    try {
      this.scanning = false;
      await this.page.cancelCurrentPayment?.();
    } finally {
      this.cancellingPay = false;
    }
  }

  onDecode(code) {
    this.$root.$emit("scanner", { code });
    this.scanning = false;
  }

  async kioskReset(reason?: string) {
    this.scanning = false;
    this.payLoading = false;
    if (this.session.status === "done") {
      this.$pos.analyticsManager.logOrderEvent("finishOrder", this.session._id);
    }
    this.nextOrder(undefined, { reason });
  }

  async kioskStart() {
    await this.session.ensureOrder(true);
    await this.$pos.analyticsManager.beginOrder(this.session._id);
    const welcomeAd = this.$pos.welcomeAds[(Math.random() * this.$pos.welcomeAds.length) | 0];
    if (welcomeAd) {
      await (this.$refs.adPlayer as any).playAd(welcomeAd);
    }
  }

  async playAd(ad: FindType<"shop/ads">) {
    await (this.$refs.adPlayer as any).playAd(ad, ad.allowSkip);
  }

  @Watch("$pos.ipsManager.fenceId")
  async onFenceChange() {
    this.$pos.analyticsManager.logOrderEvent("zoneEnter", this.session._id, {
      zone: { _id: this.$pos.ipsManager.fenceId, name: this.$pos.ipsManager.fenceName },
    });
  }

  alertTask: StopHandle;

  get outOfZone() {
    return this.$pos.ipsManager.outOfActiveZone || (this.isPaying && this.$pos.ipsManager.outOfCheckoutZone);
  }

  @Watch("outOfZone", { immediate: true })
  async onActiveZone() {
    if (this.outOfZone) {
      const buf = await loadAudioFile(AUDIO_FILE);
      if (!this.outOfZone || this.alertTask) {
        return;
      }
      this.alertTask = playAudioBuffer(buf, undefined, -1);
    } else {
      if (this.alertTask) {
        this.alertTask.stop();
        this.alertTask = null;
      }
    }
  }
}
