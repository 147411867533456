var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:`variant-${_vm.variant} bg-[var(--bg-color)]`,style:({ '--bg-color': _vm.bgColor })},[_c('div',{staticClass:"d-flex flex-col gap-y-6"},[_c('div',{staticClass:"d-flex flex-col gap-y-6 text-[var(--fg-color)] pb-6 pl-7.5",class:{ 'items-center': _vm.titleDisplayMode === 'center', 'items-end': _vm.titleDisplayMode === 'right' },style:({
        '--fg-color': _vm.fontColor,
      })},[(_vm.$td(_vm.title))?_c('div',{staticClass:"font-bold text-4xl <md:text-xl pb-2",domProps:{"innerHTML":_vm._s(_vm.$htmlContent(_vm.$td(_vm.title)))}}):_vm._e(),(_vm.$td(_vm.remarks))?_c('div',{staticClass:"font-bold text-xl mb-6 <md:text-base",domProps:{"innerHTML":_vm._s(_vm.$htmlContent(_vm.$td(_vm.remarks)))}}):_vm._e()]),_c('div',{staticClass:"d-flex align-center"},[(_vm.$breakpoint.lgAndUp)?_c('rarrow-svg',{staticClass:"custom-icon w-8 h-8 transform rotate-180",class:{
          'opacity-0 pointer-events-none': _vm.selectedSlideShow === 0 || (_vm.slideShows && _vm.slideShows.length <= 1),
        },on:{"click":function($event){_vm.selectedSlideShow--}}}):_vm._e(),_c('v-carousel',{staticClass:"flex-grow basis-0",attrs:{"hide-delimiter-background":"","delimiter-icon":"stop","hide-delimiters":"","show-arrows":false,"height":'max-content'},model:{value:(_vm.selectedSlideShow),callback:function ($$v) {_vm.selectedSlideShow=$$v},expression:"selectedSlideShow"}},[(_vm.slideShows && _vm.slideShows.length)?[_vm._l((_vm.slideShows),function(slideShow,slideShowIdx){return [_c('v-carousel-item',{staticStyle:{"background":"#ffffff"},attrs:{"contain":"","height":_vm.slideHeight}},[_c('slide-show',{attrs:{"slideShow":slideShow,"hotspotColor":_vm.hotspotColor}})],1)]})]:_vm._e()],2),(_vm.$breakpoint.lgAndUp)?_c('rarrow-svg',{staticClass:"custom-icon w-8",class:{
          'opacity-0 pointer-events-none':
            _vm.slideShows && (_vm.selectedSlideShow === _vm.slideShows.length - 1 || _vm.slideShows.length <= 1),
        },on:{"click":function($event){_vm.selectedSlideShow++}}}):_vm._e()],1)])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }