import { render, staticRenderFns } from "./orderReview.vue?vue&type=template&id=76f4c63c&scoped=true"
import script from "./orderReview.vue?vue&type=script&lang=ts"
export * from "./orderReview.vue?vue&type=script&lang=ts"
import style0 from "./orderReview.vue?vue&type=style&index=0&id=76f4c63c&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76f4c63c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRating } from 'vuetify/lib/components/VRating';
installComponents(component, {VRating})


/* hot reload */
if (module.hot) {
  var api = require("/Users/kelson/Desktop/projects/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('76f4c63c')) {
      api.createRecord('76f4c63c', component.options)
    } else {
      api.reload('76f4c63c', component.options)
    }
    module.hot.accept("./orderReview.vue?vue&type=template&id=76f4c63c&scoped=true", function () {
      api.rerender('76f4c63c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/dialogs/pos/orderReview.vue"
export default component.exports