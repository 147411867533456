var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:(_vm.stretchType === 'full' ? { width: '100vw', 'margin-left': 'calc((100% - 100vw) / 2)' } : {})},[_c('v-carousel',{attrs:{"hide-delimiter-background":"","dark":"","show-arrows-on-hover":"","show-arrows":_vm.manualChange && _vm.slides && _vm.slides.length > 1,"delimiter-icon":"stop","hide-delimiters":"","cycle":_vm.autoPlay,"interval":_vm.time,"height":_vm.bannerHeight},model:{value:(_vm.carouselNum),callback:function ($$v) {_vm.carouselNum=$$v},expression:"carouselNum"}},[(_vm.slides && _vm.slides.length)?[_vm._l((_vm.slides),function(item,idx){return [_c('v-carousel-item',{staticClass:"relative text-[var(--fg-color)]",staticStyle:{"background":"#070504"},style:({ '--bg-color': item.bgColor, '--fg-color': item.fgColor }),attrs:{"contain":"","height":_vm.bannerHeight}},[_c('div',{class:`variant-${_vm.variant}`},[(item.type === 'video')?_c('video',{staticClass:"video-banner",style:(`height: ${_vm.bannerHeight};} object-fit: contain;`),attrs:{"muted":"","autoplay":"","playsinline":"","preload":"","poster":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.$attach(item.translateFile && item.translateFile.length ? _vm.$td(item.translateFile) : item.file) +
                    '.mp4',"type":"video/mp4"}}),_vm._v(" Your browser does not support HTML5 video. ")]):_c('v-img',{attrs:{"src":_vm.$image(item.translateFile && item.translateFile.length ? _vm.$td(item.translateFile) : item.file),"sizes":"100vw","height":_vm.bannerHeight,"eager":""}}),_c('div',{staticClass:"d-flex justify-center flex-col",class:`content ${_vm.convertPostion(item.positionIndex)} ${
                  _vm.$breakpoint.mdAndDown ? 'w-4/5' : 'lg:p-30 md:p-15 <md:p-8'
                }`},[(_vm.$td(item.title))?_c('div',{staticClass:"ql-editor text-4xl <md:text-xl",domProps:{"innerHTML":_vm._s(_vm.$htmlContent(_vm.$td(item.title)))}}):_vm._e(),(_vm.$td(item.content))?_c('div',{staticClass:"ql-editor text-xl <md:text-base",domProps:{"innerHTML":_vm._s(_vm.$htmlContent(_vm.$td(item.content)))}}):_vm._e(),_c('div',{staticClass:"d-flex gap-x-4 pl-2 pt-6",class:_vm.buttonAlign(item.positionIndex)},[(item.mainButton && item.mainButton.enabled)?_c(_vm.getLink(item.mainButton),_vm._b({tag:"component",staticClass:"py-3 px-6 min-w-32.5 rounded-full !text-[var(--main-button-fg-color)] !bg-[var(--main-button-bg-color)] text-base text-center",style:({
                    '--main-button-bg-color': item.mainButton.bgColor,
                    '--main-button-fg-color': item.mainButton.fgColor,
                  })},'component',_vm.getLinkProp(item.mainButton),false),[_vm._v(" "+_vm._s(_vm.$td(item.mainButton.title))+" ")]):_vm._e(),(item.subButton && item.subButton.enabled)?_c(_vm.getLink(item.subButton),_vm._b({tag:"component",staticClass:"py-3 px-6 min-w-32.5 rounded-full !text-[var(--sub-button-fg-color)] !bg-[var(--sub-button-bg-color)] text-base text-center",style:({
                    '--sub-button-bg-color': item.subButton.bgColor,
                    '--sub-button-fg-color': item.subButton.fgColor,
                  })},'component',_vm.getLinkProp(item.subButton),false),[_vm._v(" "+_vm._s(_vm.$td(item.subButton.title))+" ")]):_vm._e()],1)])],1)])]})]:[_c('v-carousel-item',{staticStyle:{"background":"black"},attrs:{"contain":"","src":_vm.$image(_vm.$store.state.shop.icon)}})]],2)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }