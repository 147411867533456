

import _ from 'lodash'
import cookie from 'js-cookie';
import moment from 'moment'
const expires = 365;

export const state = () => ({
  locales: {
    'en': { name: 'English', iso: 'en-us', code: 'en' },
    'zh-cn': { name: '简体中文', iso: 'zh-cn', code: 'zh-cn' },
    'zh-hk': { name: '繁體中文', iso: 'zh-hk', code: 'zh-hk' },
  },
  locale: 'zh-hk',
  title: "",
  titles: null,
  nestedBack: false,
  args: {},
  actions: [],
  processing: null,
  shop: null,
  shops: null,
  local: {},
  localInit: false,
  fullscreen: false,
  fullPage: false,
  noPadding: false,
  noDrawer: false,
  hostname: 'boxs.hk',
  dark: false,
  currency: {
    symbol: '$',
  },
  success: '',
  error: '',
  help: false,

  translateLocale: '',

  numPadOptions: null,
  hydrated: false,
  connected: true,
  useFontSize: false,
  disableTextSelect: false,
  tableMode: false,
  customHeader: false,
  inited: false,
  user: {},
  profile: null,

  ifUrl: '',
  cast: false,
  numPadDialog: false,

  savedLogins: [],

  quillEditorPresetStyle: [],
  quillEditorFontList: [],
  customFonts: [],

  errorTimeout: 5000,
})


export const getters = {
  margs: state => {
    return _.mapValues(state.args, it => it && it.toString())
  },

  alias: state => {
    if (state.shop && state.shop.alias) return state.shop && state.shop.alias;
    const item = state.shop && state.shop.subdomain;
    if (item) return `${state.ifProto}${item}.${state.ifDomain}`;
    else return state.ifUrl;
  },


  prefix: state => {
    const item = state.shop && state.shop.subdomain;
    if (item) return `${state.ifProto}${item}.${state.ifDomain}`;
    else return state.ifUrl;
  },

  localeCode: state => {
    const item = state.locales[state.locale];
    return item?.iso ?? item?.code ?? state.locale;
  },

  attributes: state => {
    if (!state.shop) return {};
    return state.shop.attributes || {};
  },

  role: state => {
    if (!state.shop || !state.user) return 'none';
    return state.shop.role || 'none';
  },

  permissions: state => {
    if (!state.shop || !state.user) return 'none';
    return state.shop.permissions || [];
  },

  permissionDict: (state, getters) => {
    return _.fromPairs(_.map(getters.permissions, r => [r, true]))
  },

  local: (state) => {
    let shop = state.shop;
    if (!shop) return {};
    let config = state.local[shop._id];
    if (!config) return {};
    return config;
  },

  userId(state, getters) {
    return state.user._id
  },
  isVerified(state) {
    return state.user && state.user.verified || false;
  },

  resetRequired(state) {
    return state.user && state.user.resetRequired || false;
  },
}

export const mutations = {
  SET_INIT_PARAMS(state, { ifProto, ifUrl, ifDomain }) {
    state.ifDomain = ifDomain;
    state.ifUrl = ifUrl;
    state.ifProto = ifProto;
  },

  SET_HELP(state, help) {
    state.help = help;
  },

  SET_LANG(state, locale) {
    state.locale = locale
  },

  SET_TITLE(state, { title, titles, actions, fullPage, args, noDrawer, dark, useFontSize, disableTextSelect, tableMode, nestedBack, noPadding, customHeader }) {
    state.title = title || '';
    state.titles = titles ? titles : null;
    state.nestedBack = nestedBack || false;
    state.fullPage = fullPage || false;
    state.actions = actions || [];
    state.args = args || {};
    state.noDrawer = noDrawer || false;
    state.dark = dark || false;
    state.useFontSize = useFontSize || false;
    state.disableTextSelect = disableTextSelect || false;
    state.tableMode = tableMode || false;
    state.noPadding = noPadding || false;
    state.customHeader = customHeader || false;
  },

  SET_TITLE_ARGS(state, args) {
    state.args = args || {};
  },

  SET_PROCESSING(state, processing) {
    if (state.processing !== processing)
      state.processing = processing;
  },

  SET_ACTION(state, args) {
    const action = state.actions.find(it => it.action === args.action);
    if (action) {
      _.assign(action, args);
    }
  },

  INIT(state) {
    if (state.inited) return;
    state.inited = true;

    const login = localStorage.getItem("login")
    if (login) {
      const info = JSON.parse(login);
      state.user = info || {};
    }
    const profile = localStorage.getItem("profile")
    state.profile = profile;

    const savedLogins = localStorage.getItem("savedLogins");
    if (savedLogins) {
      state.savedLogins = JSON.parse(savedLogins);
    }
  },

  SET_USER(state, user) {
    if(state.user?._id !== user?._id) {
      state.shops = null;
    }
    state.user = user || {};
    localStorage.setItem("login", JSON.stringify(state.user));
  },

  SET_SHOP(state, shop) {
    console.log('set shop', shop?._id);
    if (state.shop && state.shop._id === shop._id) {
      _.assign(state.shop, shop);
    }
    else {
      state.shop = shop;
      if (shop && state.shops && !state.shops.find(it => it._id === shop._id)) {
        state.shops.push(shop);
      }
    }
  },

  SET_SHOPS(state, { shops, shop }) {
    shops = shops || [];
    state.shops = shops;
    state.shop = shop || null;
  },

  SET_FULLSCREEN(state, fullscreen) {
    state.fullscreen = fullscreen;
  },


  SET_URL(state, { hostname }) {
    state.hostname = hostname;
  },

  LOGOUT(state) {
    const removeId = state.user?._id;
    state.shop = null;
    state.user = {};
    state.shops = null;
  
    if (removeId) {
      const idx = state.savedLogins.findIndex(it => it.user._id === removeId);
      if (idx !== -1) {
        state.savedLogins.splice(idx, 1);
        localStorage.setItem("savedLogins", JSON.stringify(state.savedLogins));
      }
    }
  },

  SET_SUCCESS(state, message) {
    state.success = message;
  },

  SET_ERROR(state, error) {
    if(typeof error === 'string') {
      state.error = error;
      state.errorTimeout = 5000;
    } else if (typeof error === 'object') {
      state.error = error.message || error.error || error.toString();
      state.errorTimeout = error.timeout || 5000;
    } else {
      state.error = '';
    }
  },

  INIT_LOCAL(state) {
    if (state.localInit) return;
    try {
      const local = localStorage["local"];
      if (local) {
        state.local = JSON.parse(local);
      }
      state.localInit = true;
    } catch (e) {
    }
  },

  SET_LOCAL(state, local) {
    let shop = state.shop;
    if (!shop) return;
    let config = state.local[shop._id];
    if (!config) {
      config = state.local[shop._id] = {};
    }

    state.local[shop._id] = Object.assign({}, config, local);
    localStorage["local"] = JSON.stringify(state.local);
  },

  SET_TRANSLATE_LOCALE(state, locale) {
    state.translateLocale = locale
  },

  SET_NUM_PAD_OPTS(state, numPadOptions) {
    state.numPadOptions = numPadOptions;
  },

  SET_HYDRATED(state) {
    state.hydrated = true;
  },

  SET_CONNECTED(state, connected) {
    state.connected = connected;
  },

  SET_CAST_MODE(state) {
    state.cast = true;
    state.user = {};
    state.inited = true;
    state.jwt = "";
    state.profile = "";
  },


  ADD_LOGIN(state, loginData) {
    const cur = state.savedLogins.find(it => it.user._id === loginData.user._id);
    if (cur) {
      cur.accessToken = loginData.accessToken;
      cur.user = loginData.user;
      cur.shop = loginData.shop || null;
    } else {
      state.savedLogins.push({
        accessToken: loginData.accessToken,
        user: loginData.user,
        shop: loginData.shop || null,
      });
    }
    localStorage.setItem("savedLogins", JSON.stringify(state.savedLogins));
  },

  UPDATE_LOGIN(state, loginData) {
    const cur = state.savedLogins.find(it => it.user._id === loginData.user._id);
    if (cur) {
      cur.accessToken = loginData.accessToken;
      cur.user = loginData.user;
      localStorage.setItem("savedLogins", JSON.stringify(state.savedLogins));
    }
  },

  REMOVE_LOGIN(state, removeId) {
    const idx = state.savedLogins.findIndex(it => it.user._id === removeId);
    if (idx !== -1) {
      state.savedLogins.splice(idx, 1);
      localStorage.setItem("savedLogins", JSON.stringify(state.savedLogins));
    }
  },
  
  SET_LOCALES(state, locales) {
    state.locales = locales;
  },

  SET_NUMPAD(state, numPadDialog){
    state.numPadDialog = numPadDialog;
  },

  SET_CUSTOMFONTS(state, customFonts){
    state.customFonts = customFonts;
  },

  SET_QUILLEDITORFONTLIST(state, quillEditorFontList){
    state.quillEditorFontList = quillEditorFontList;
  },

  SET_QUILLEDITORPRESETSTYLE(state, quillEditorPresetStyle){
    state.quillEditorPresetStyle = quillEditorPresetStyle;
  },

}

const cookieName = 'locale';

export const actions = {
  initLang({ state, dispatch }, { ctx }) {
    let locale = state.locale;
    const lang = (window.navigator as any).userLanguage || window.navigator.language;
    const l = _.filter(state.locales, l => l.code === lang)[0];
    if (l) locale = l.id;
    dispatch('changeLang', { code: cookie.get(cookieName) || locale, ctx, $i18n: ctx.i18n || ctx.$i18n || ctx?.app?.i18n, init: true });
  },

  async saveShop({ commit, state, getters }, { feathersClient, shop }) {
    const shops = feathersClient.service('shops');
    shop = { ...shop };

    if (state.shop) {
      if (shop.alias === null) {
        delete shop.alias;
        shop.$unset = {
          alias: "",
        }
      }
      shop = await shops.patch(state.shop._id, shop, {
        query: {
          $populate: ['icon']
        }
      });
      commit('SET_SHOP', shop);
    } else {
      if (shop.alias === null) {
        delete shop.alias;
      }
      const newShop = await shops.create(shop, {
        query: {
        }
      });
      commit('SET_SHOP', newShop || null);
    }
  },

  async changeLang({ commit, state }, { code, $i18n, init }) {
    const item = state.locales[code];
    if (item) {
      if (!init) {
        cookie.set(cookieName, code, { expires });
      }
      commit('SET_LANG', code);
      await $i18n.loadRemoteLocale(code);
      $i18n.locale = code;
      // switch(item.locale) {
      //     case 'en-us':
      //         break;
      //     case 'zh-cn':
      //         await import('moment/locale/zh-cn');
      //         break;
      //     case 'zh-hk':
      //         await import('moment/locale/zh-hk');
      //         break;
      // }
      moment.locale(item.locale);
    }
  },

  saveLocal({ commit, state }, local) {
    commit('SET_LOCAL', local);
    localStorage['local'] = JSON.stringify(state.local);
  }

}

