import { render, staticRenderFns } from "./AdjustFont.vue?vue&type=template&id=75405706"
import script from "./AdjustFont.vue?vue&type=script&lang=ts"
export * from "./AdjustFont.vue?vue&type=script&lang=ts"
import style0 from "./AdjustFont.vue?vue&type=style&index=0&id=75405706&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VSlider})


/* hot reload */
if (module.hot) {
  var api = require("/Users/kelson/Desktop/projects/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('75405706')) {
      api.createRecord('75405706', component.options)
    } else {
      api.reload('75405706', component.options)
    }
    module.hot.accept("./AdjustFont.vue?vue&type=template&id=75405706", function () {
      api.rerender('75405706', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/dialogs/AdjustFont.vue"
export default component.exports