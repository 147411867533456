
import { ns } from './messageQueue'
import { EventEmitter } from 'events';
import { getVersion } from './nativeIntegrations'

export async function create() {
    return new BluetoothSerialDevice(await ns('bluetoothSerial').call('create'));
}

export interface BluetoothSerialDeviceInfo {
    name : string
    address : string
}

export class BluetoothSerialDevice extends EventEmitter {
    handle: string
    closed = false;

    constructor(public id : string) {
        super();
        this.handle = ns('bluetoothSerial').on(`data-${this.id}`, (buf : Buffer) => {
            if(!this.handle) return;
            this.emit('data', Buffer.from(new Uint8Array(buf)));
        })
    }

    async inquire() : Promise<BluetoothSerialDeviceInfo[]> {
        return await ns('bluetoothSerial').call('inquire', this.id)
    }

    async connect(address) {
        return await ns('bluetoothSerial').call('connect', this.id, address)
    }

    async send(data) {
        const buf = Array(data.length);
        for(let i = 0; i < data.length; i++)
            buf[i] = data[i];
        await ns('bluetoothSerial').call('send', this.id, buf);
    }

    async close() {
        if(this.closed) return;
        this.closed = true;
        ns('bluetoothSerial').off(this.handle);
        return await ns('bluetoothSerial').call('close', this.id)
    }
}

export function supported() : Promise<boolean> {
    if(!getVersion()) return Promise.resolve(false);
    return Promise.race([
        ns('bluetoothSerial').call<boolean>('supported'),
        new Promise<boolean>(resolve => setTimeout(() => resolve(false), 1000)),
    ])
}

