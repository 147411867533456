
import { create, BluetoothSerialDevice } from 'pos-printer/bluetoothSerial'
import { getVersion } from '../../nativeIntegrations'

class WeightDevice {
    connected = false;
    device : BluetoothSerialDevice;
    context : any;
    timeout : any;
    connecting = false;
    input : string[] = [];

    constructor(context : any) {
        this.context = context;
        this.connected = false;
        this.connecting = false;
        this.device = null;
        this.input = [];
        this.timeout = null;
    }

    async connect() {
        try {
            if(this.device || this.connecting) return;
            this.connecting = true;

            const device = await create();
            this.device = device;
            const devices = await device.inquire();
            const d = devices.find(it => it.name === 'BT04-A');
            if(!d) return;
            device.on('data', buf => {
                const textDecode = {
                    0x1e: '1',
                    0x1f: '2',
                    0x20: '3',
                    0x21: '4',
                    0x22: '5',
                    0x23: '6',
                    0x24: '7',
                    0x25: '8',
                    0x26: '9',
                    0x27: '0',
                    0x28: '\n',
                    0x37: '.',
                }
                const data = Array.prototype.slice.call(buf).filter(it => it).map(it => textDecode[it]).filter(it => it);
                const line = data.join('');
                const parts = line.split('\n');
                parts.forEach((it, idx) => {
                    idx && this.flushBuffer();
                    it && this.inputBuffer(it);
                })
            })
            await device.connect(d.address);
            this.connected = true;
        } finally {
            this.connecting = false;
        }

    }

    inputBuffer(data) {
        this.input.push(data);
        if(this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.timeout = setTimeout(this.flushBuffer.bind(this), 500);
    }

    flushBuffer() {
        const line = this.input.join('');
        if(this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if(line) {
            const f = parseFloat(line);
            if(!isNaN(f)) {
                this.context.$emit('weight', f);
            }
        }

        this.input = [];
    }

    async disconnect() {
        if(this.device) {
            await this.device.close();
        }
        this.device = null;
    }
}

let device : WeightDevice;

export function init(ctx) {
    if(!getVersion()) return;

    if(device) return device;
    device = new WeightDevice(ctx);
    device.connect();
    return device;
}

