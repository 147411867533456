var render, staticRenderFns
import script from "./ButtonBase.vue?vue&type=script&lang=ts"
export * from "./ButtonBase.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kelson/Desktop/projects/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4488b1da')) {
      api.createRecord('4488b1da', component.options)
    } else {
      api.reload('4488b1da', component.options)
    }
    
  }
}
component.options.__file = "dep/feathers-client/components-internal/ButtonBase.vue"
export default component.exports