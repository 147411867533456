
import { checkID, Component, FindPopRawType, FindType, Prop, Vue, Watch } from "@feathers-client";

@Component
export default class CommentFilter extends Vue {
  @Prop()
  label: string;

  @Prop()
  rating: number;

  @Prop()
  comments: FindPopRawType<["user"], "shop/product/comments">[];

  get detailRating() {
    if (this.rating === 0) {
      return this.comments.length;
    }
    return this.comments.filter(comment => comment.rating === this.rating).length;
  }
}
