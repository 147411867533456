
import { checkID, Component, FindPopRawType, FindType, Prop, Vue, Watch, getID } from "@feathers-client";

export type ProductCardInfo = {
  id: number;
  image: string;
  tag: { color: string; text: string }[];
  productTitle: string;
  amount?: number;
  amountUnit?: string;
  subAmount?: number;
  subAmountUnit?: string;
  subAmountPrice?: number;
  sku: string;
  originalPrice: number;
  price: number;
  amountSold: number;
  rating: number;
  ratingNumber: number;
  comments: FindPopRawType<["user"], "shop/product/comments">[];
} & FindType<"shop/product/skus">;
/* example prop object
{
  id: 1,
  image: "https://image.made-in-china.com/226f3j00rFEcVIUROKoz/Wholesale-100g-Empty-Square-Instant-Glass-Coffee-Jar-with-Gold-Color-Plastic-Lid.webp",
  tag: [
    { color: "#997300", text: "日本直送" },
    { color: "#8A1E1E", text: "買二送一" },
  ],
  productTitle: "北海道 - 特選3.6牛乳 1公升",
  amount: 1,
  amountUnit: "公升",
  pricePerAmount: null,
  sku: "9734028723",
  originalPrice: 24,
  price: 24,
  amountSold: 1000,
  rating: 5,
  ratingNumber: 21,
}
{
  id: 1,
  image: "https://nutritionsource.hsph.harvard.edu/wp-content/uploads/2018/08/bananas-1354785_1920-1024x683.jpg",
  tag: [],
  productTitle: "4011 香蕉",
  amount: 1,
  amountUnit: "包",
  subAmount: 2,
  subAmountUnit: "件",
  subAmountPrice: 3,
  sku: "9734028723",
  // originalPrice: 6,
  price: 6,
  amountSold: 10000,
  rating: 5,
  ratingNumber: 21,
}
*/

@Component
export default class ProductCard extends Vue {
  @Prop()
  productInfo: ProductCardInfo;

  @Prop()
  variant: "light" | "dark";

  @Prop(Boolean)
  clickable: Boolean;

  get thumb() {
    return (this.productInfo?.image && this.$thumb(this.productInfo.image)) || require("~/assets/images/logo.png");
  }

  get rating() {
    return this.productInfo.rating;
  }

  get tagColorClass() {
    return `bg-[${this.productInfo.tag}]`;
  }

  get darkMode() {
    return this.variant === "dark";
  }

  get lightMode() {
    return this.variant === "light";
  }

  get id(){
    return (this.productInfo as any)?.slug || getID(this.productInfo) || this.productInfo?.id || this.productInfo?._id 
  }
  // get ratingStars() {
  //   return Array.from({ length: 5 }, (_, index) => {
  //     return index < this.productInfo.rating ? "★" : "☆"
  //   }).join("")
  // }

  get amountString() {
    return `${this.productInfo.amount}${this.productInfo.amountUnit ?? "件"}`;
  }

  get subAmountString() {
    return `${this.productInfo.subAmount}${this.productInfo.subAmountUnit ?? "件"} @
          ${this.productInfo.subAmountPrice}/${this.productInfo.subAmountUnit ?? "件"}`;
  }

  async openDialog() {
    if (this.clickable) this.$emit("productCardClick");
  }
}
