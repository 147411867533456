
import { Context } from '@nuxt/types'
import Url from 'url'
import qs from 'qs'

export default (ctx: Context) => {
    const store = ctx.store;
    (ctx.app.$feathers as any).hooks({
        before: {
            async get(hook) {
              if(typeof hook.id === 'object') debugger;
                if(hook.params.query && hook.params.query.$shop || hook.path.startsWith('shop/') || hook.path.startsWith('attachments')) {
                    if(hook.params.query) delete hook.params.query.$shop;
                }
            },
            async find(hook) {
                if(hook.params.query && hook.params.query.$shop || hook.path.startsWith('shop/') || hook.path.startsWith('attachments')) {
                    const shop = store.state.shop;
                    if(!shop) {
                        ctx.redirect('/dashboard');
                        return;
                    }
                    hook.params.query.shop = shop._id;
                    if(hook.params.query) delete hook.params.query.$shop;
                }
            },
            async create(hook) {
                const $shop = (hook.params.query && hook.params.query.$shop) || (hook.data && hook.data.$shop) || hook.path === 'printerShares' || hook.path === 'planInfo/sso' || hook.path.startsWith('shop/')|| hook.path.startsWith('attachments');
                if(hook.path === 'imports/entry' && hook.data.service.startsWith('shop/')) {
                    const shop = store.state.shop;
                    hook.data.entry.shop = shop._id;
                    return;
                }
                if($shop) {
                    const shop = store.state.shop;
                    if(!shop) {
                        ctx.redirect('/dashboard');
                        return;
                    }
                    if(hook.data) {
                        delete hook.data.$shop;
                        hook.data.shop = shop._id;
                    }
                    if(hook.params.query) {
                        delete hook.params.query.$shop;
                        hook.params.query.shop = shop._id;
                    }
                }
            },
            async update(hook) {
                const $shop = (hook.params.query && hook.params.query.$shop) || (hook.data && hook.data.$shop) || hook.path.startsWith('shop/')|| hook.path.startsWith('attachments');
                if($shop) {
                    const shop = store.state.shop;
                    if(!shop) {
                        ctx.redirect('/dashboard');
                        return;
                    }
                    if(hook.data) delete hook.data.$shop;
                    if(hook.params.query) delete hook.params.query.$shop;
                    if(!hook.data) hook.data = {};
                    hook.data.shop = shop._id;
                    if(!hook.params.query) hook.params.query = {};
                    hook.params.query.shop = shop._id;
                }
            },
            async patch(hook) {
                const $shop = (hook.params.query && hook.params.query.$shop) || (hook.data && hook.data.$shop) || hook.path.startsWith('shop/')|| hook.path.startsWith('attachments');
                if($shop) {
                    const shop = store.state.shop;
                    if(!shop) {
                        ctx.redirect('/dashboard');
                        return;
                    }
                    if(hook.data) delete hook.data.$shop;
                    if(hook.params.query) delete hook.params.query.$shop;
                    hook.params.query = hook.params.query || {};
                    hook.params.query.shop = shop._id;
                }
            },
            async remove(hook) {
                if(hook.params.query && hook.params.query.$shop || hook.path.startsWith('shop/')|| hook.path.startsWith('attachments')) {
                    const shop = store.state.shop;
                    if(!shop) {
                        ctx.redirect('/dashboard');
                        return;
                    }
                    if(hook.params.query) delete hook.params.query.$shop;
                    hook.params.query = hook.params.query || {};
                    hook.params.query.shop = shop._id;
                }
            }
        },
    });

    const oldPost = ctx.app.$feathers.post;
    ctx.app.$feathers.post = <any>function(url, data, params) {
        const u = Url.parse(url);
        const q = qs.parse(u.query);

        if(ctx.app.store.state.shop && url !== 'authentication') {
            q.shop = ctx.app.store.state.shop._id
        }
        u.query = qs.stringify(q);
        url = u.path + '?' + u.query;
        return oldPost(url, data, params);
    };
}
