
import { Component, Vue, Prop, Watch, FindType } from "@feathers-client";

const validPhoneRegex = /^[0-9]{8}$/;

@Component
export default class Member extends Vue {
  @Prop()
  session;

  scanning = false;

  isLogin = false;

  phone = "";
  verificationCode = "";

  stage = 0;

  user: FindType<"shop/users"> = null;

  reset() {
    this.phone = "";
    this.verificationCode = "";
    this.stage = 0;
  }

  cancel() {
    this.$emit("cancel");
  }

  async savePersonalInfo() {
    this.user = await this.$feathers.service("shop/users").patch(this.user._id, {
      fullName: this.user.fullName,
      email: this.user.email,
    });
    this.checkPersonalInfo();
  }

  async loginOrRegister() {
    if (!validPhoneRegex.test(this.phone)) {
      return;
    }
    this.user = await this.getUser();
    if (!this.user) {
      this.user = await this.createUser();

      this.$pos.analyticsManager.logOrderEvent("register", this.session._id, { user: this.user });
      console.log("user not exists but created", this.user);
    } else {
      console.log("user exists", this.user);
    }

    this.checkVerified();
  }

  async checkVerified() {
    // if (!this.user.smsVerified) {
    //   await this.sendVerificationSms();
    //   this.stage = 1;
    //   return;
    // }
    // this.$store.commit("SET_SUCCESS", this.$td("kiosk.verified"));
    this.checkPersonalInfo();
  }

  async checkPersonalInfo() {
    if (!((this.$td(this.user.localeFullName) || this.user.fullName) && this.user.email)) {
      this.$store.commit("SET_ERROR", this.$t("kiosk.fillPersonalInfo"));
      this.stage = 2;
      return;
    }  
    var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!re.test(this.user.email)){
      this.$store.commit("SET_ERROR", this.$t("format.email"));
      this.stage = 2
      return
    } 
    this.$store.commit("SET_SUCCESS", this.$t("kiosk.alldone"));
    this.$emit("login", this.user);
  }

  async verify() {
    const res = await this.verifyCode();
    if (!res) {
      return;
    }
    this.checkVerified();
  }

  async verifyCode() {
    try {
      const user = await this.$feathers.service("shop/users/verification/verify").create({
        auth: "sms",
        code: this.verificationCode,
        phone: this.phone,
      });
      console.log("verifyCode as user", user);
      this.user = user;
      this.$store.commit("SET_SUCCESS", this.$td("kiosk.verified"));
      return true;
    } catch (e) {
      // console.error(e);
      this.$store.commit("SET_ERROR", e.data && e.data.messaget ? this.$td(e.data.messaget) : e.message);
    }
  }

  async sendVerificationSms(noAlert = false) {
    try {
      await this.$feathers.service("shop/users/verification/sms").create({ phone: this.phone });
      this.$store.commit("SET_SUCCESS", this.$td("kiosk.smsSent"));
    } catch (e) {
      !noAlert && this.$store.commit("SET_ERROR", e.data && e.data.messaget ? this.$td(e.data.messaget) : e.message);
    }
  }

  async getUser() {
    return (
      await this.$feathers.service("shop/users").find({
        query: {
          phone: this.phone,
        },
      })
    ).data[0];
  }

  async createUser() {
    return await this.$feathers.service("shop/users").create({
      phone: this.phone,
    });
  }

  async flushUpdates() {
    this.session.saveCart();
  }
}
