import { Vue, Component, FindType } from "@feathers-client";
import type { PosHelper } from "../pos";
import { Order } from "~/common/pos";

@Component
export class AnalyticsManager extends Vue {
  get parent() {
    return this.$parent as PosHelper;
  }

  logOrderEvent(type: string, order: string, props: Record<string, any> = {}) {
    (async () => {
      await this.$feathers.service("shop/actionLogs").create({
        actionType: "orderManage",
        desc: type,
        date: new Date(),
        order: order,
        cashier: this.parent.cashier?._id,
        detail: props,
      });
    })().catch(console.error);
  }

  logAdEvent(type: string, ad: string, duration?: number) {
    (async () => {
      await this.$feathers.service("shop/adLogs").create({
        type,
        date: new Date(),
        cashier: this.parent.cashier?._id,
        ad,
        duration,
      });
    })().catch(console.error);
  }

  orderId: string = null;
  curEvent: FindType<"shop/order/positions"> = null;
  lastLogPosition: [number, number, number] = null;
  ipsPositionLogTime: number = 0;

  async beginOrder(order: string) {
    if (this.orderId) {
      await this.endOrder(this.orderId);
    }
    this.orderId = order;
    if (order) {
      this.$pos.analyticsManager.logOrderEvent("createOrder", order);
    }
    await this.logPositionEvent(true);
    this.$pos.ipsManager.traceLogPosition(undefined, true);
  }

  endOrder(order: string) {
    (async () => {
      if (!order) {
        order = this.orderId;
      }
      if (this.orderId !== order) return;
      if (this.orderId) {
        await this.logPositionEvent(true);
        this.$pos.ipsManager.traceLogPosition(undefined, true);
        this.orderId = null;
      }
    })().catch(console.error);
  }

  async logPositionEvent(force = false) {
    if (!this.orderId) return false;
    const position = this.$pos?.ipsManager?.position;
    if (!position) return false;

    const dist = this.lastLogPosition
      ? Math.sqrt(
          Math.pow(position[0] - this.lastLogPosition[0], 2) + Math.pow(position[1] - this.lastLogPosition[1], 2),
        )
      : 0;

    if (
      force ||
      (Date.now() - this.ipsPositionLogTime < (this.$config.features.ipsPositionLogInterval ?? 60) * 1000 && dist < 2)
    )
      return false;

    if (this.curEvent && this.curEvent.positions.length < 100 && this.curEvent.mapId === this.$pos?.ipsManager?.mapId) {
      this.curEvent = await this.$feathers.service("shop/order/positions").patch(this.curEvent._id, {
        $push: {
          positions: { time: new Date(), x: position[0], y: position[1], z: position[2] },
        },
      });
    } else {
      this.curEvent = await this.$feathers.service("shop/order/positions").create({
        order: this.orderId,
        cashier: this.parent.cashier?._id,
        mapId: this.$pos?.ipsManager?.mapId,
        positions: [{ time: new Date(), x: position[0], y: position[1], z: position[2] }],
      });
    }
    this.ipsPositionLogTime = Date.now();
    this.lastLogPosition = position;
    return true;
  }
}
