import { render, staticRenderFns } from "./SlideShow.vue?vue&type=template&id=375152a1&scoped=true"
import script from "./SlideShow.vue?vue&type=script&lang=ts"
export * from "./SlideShow.vue?vue&type=script&lang=ts"
import style0 from "./SlideShow.vue?vue&type=style&index=0&id=375152a1&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "375152a1",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VCarousel,VCarouselItem,VImg,VSheet,VSlideGroup,VSlideItem})


/* hot reload */
if (module.hot) {
  var api = require("/Users/kelson/Desktop/projects/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('375152a1')) {
      api.createRecord('375152a1', component.options)
    } else {
      api.reload('375152a1', component.options)
    }
    module.hot.accept("./SlideShow.vue?vue&type=template&id=375152a1&scoped=true", function () {
      api.rerender('375152a1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "dep/site-editor/components/ImageWithPin/components/SlideShow.vue"
export default component.exports