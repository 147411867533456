
import { checkID, Component, FindType, Prop, Vue, Watch } from "@feathers-client";
import { Order } from "@common/pos";

@Component
export default class AdPlayer extends Vue {
  @Prop()
  session: Order;

  currentAd: FindType<"shop/ads"> = null;

  currentAdResolve: () => void = null;
  currentAdCancellable = false;

  currentAdPlayTime = 0;
  currentBannerPlayTime = 0;
  adPlayTimeInterval: NodeJS.Timeout = null;
  bannerPlayTimeInterval = null;

  get banners() {
    return this.currentAd?.banners;
  }

  get canSkip() {
    return (
      this.currentAdCancellable && (!this.currentAd?.skipTimer || this.currentAdPlayTime >= this.currentAd?.skipTimer)
    );
  }

  bannerIndex = 0;

  async playAd(ad: FindType<"shop/ads">, cancellable = false) {
    this.stopLoop();

    const adPlayCount = (
      await this.$feathers.service("shop/adLogs").find({
        query: {
          ad: ad._id,
          shop: this.$pos.$shop._id,
          $limit: 0,
        },
      })
    ).total;

    if (adPlayCount >= ad.maxCount) {
      console.warn(`Ad play count exceeded, play time: ${adPlayCount}, max: ${ad.maxCount}`);
    } else {
      this.currentAd = ad;
      this.currentAdPlayTime = 0;
      // this.$feathers.service("shop/adLogs").create({
      //   ad: ad._id,
      //   shop: this.$pos.$shop._id,
      //   cashier: this.$pos.cashier._id,
      // });

      this.adPlayTimeInterval = setInterval(() => {
        this.currentAdPlayTime++;
      }, 1000);
      await this.$pos.analyticsManager.logAdEvent("view", ad._id);
      this.currentAdCancellable = cancellable;
      await new Promise<void>(resolve => {
        this.currentAdResolve = resolve;
      });
      this.currentAd = null;
      if (this.adPlayTimeInterval) {
        clearInterval(this.adPlayTimeInterval);
        this.adPlayTimeInterval = null;
      }
    }

    if (this.$pos.nextAd && !checkID(this.$pos.nextAd, ad)) {
      this.playAd(this.$pos.nextAd, this.$pos.nextAd.allowSkip);
    }
  }

  async fetchProductInfo(skus) {
    let productInfo = null;
    let productComments = [];
    let productCommentSummaries = [];
    let productGroups = [];
    try {
      productComments = await this.$feathers.service("shop/product/comments").find({
        query: {
          [this.session.viewingMode === "product" ? "product" : "sku"]: skus.map(el => el._id),
          $populate: [{ path: "user", model: "ShopUser" }],
          $sort: {
            createdAt: -1,
          },
          $paginate: false,
        },

        paginate: false,
      });
    } catch (e) {
      console.error(e);
    }
    try {
      productCommentSummaries = await Promise.all(
        skus.map(el => {
          return this.$feathers.service("shop/product/comments/summary").create({
            [this.session.viewingMode === "product" ? "product" : "sku"]: el._id,
            shop: `${this.$shop._id}`,
          });
        }),
      );
    } catch (e) {
      console.error(e);
    }

    try {
      productGroups = await Promise.all(
        skus.map(async el => {
          return (
            await this.$feathers
              .service("shop/product/searches")
              .find({ query: { productGroup: el.productGroup, $paginate: false }, paginate: false })
          )?.[0];
        }),
      );
    } catch (e) {
      console.error(e);
    }
    productInfo = {
      ...skus[0],
      image: skus[0].mainImage || skus[0].images?.[0],
      tag: productGroups[0]?.tags.map(it => ({ color: it.color, text: this.$td(it.name) })),
      sku: skus[0]._id,
      productTitle: this.$td(skus[0].displayName || skus[0].name),
      originalPrice: productGroups[0]?.originalPrice,
      price: productGroups[0]?.price,
      amountSold: productGroups[0]?.cachedSales,
      rating: productCommentSummaries[0]?.average,
      ratingNumber: productCommentSummaries[0]?.total,
      comments: productComments,
      linkedProduct: skus[0],
    };
    return productInfo;
  }

  async openProductDialog(banner) {
    try {
      // const productGroup = banner.productGroup;
      // if(!productGroup) return;
      // const skus = await this.$feathers.service("shop/product/skus").find({
      //   query:{
      //     productGroup: productGroup,
      //     $paginate: false
      //   },
      //   paginate: false
      // })
      // if (!skus.length || !skus[0]) {
      //   return;
      // }
      await this.$pos.analyticsManager.logAdEvent("click", this.currentAd._id);
      const productSku = banner.productSku;
      if (!productSku) return;
      const skus = await this.$feathers.service("shop/product/skus").find({
        query: {
          _id: productSku,
          $paginate: false,
        },
        paginate: false,
      });
      if (!skus.length || !skus[0]) {
        return;
      }
      const productInfo = await this.fetchProductInfo(skus);
      this.$openDialog("hkpc-product-ad-detail-card", {
        productInfo: productInfo,
      });
      this.$pos.analyticsManager.logOrderEvent("openProductDetails", this.session._id, {
        sku: productInfo.linkedProduct,
      });
    } catch (e) {
      console.log(e);
    }
  }

  @Watch("$pos.nextAd")
  onNextAdChanged() {
    if (!this.$pos.nextAd || checkID(this.$pos.nextAd, this.currentAd)) {
      return;
    }
    if (!this.currentAd || this.currentAdCancellable) {
      this.playAd(this.$pos.nextAd, this.$pos.nextAd.allowSkip);
    }
  }

  currentPictureIdx = 0;

  picHandler = null;

  mounted() {
    this.onPictureChanged();
  }

  beforeDestroy() {
    this.stopLoop();
    if (this.bannerPlayTimeInterval) {
      clearInterval(this.bannerPlayTimeInterval);
      this.bannerPlayTimeInterval = null;
    }
    if (this.adPlayTimeInterval) {
      clearInterval(this.adPlayTimeInterval);
      this.adPlayTimeInterval = null;
    }
  }

  startLoop() {
    if (this.picHandler) {
      return;
    }
    this.picHandler = setTimeout(async () => {
      this.picHandler = null;
      await this.showNextPicture();
    }, (this.banners?.[this.currentPictureIdx]?.duration ?? 5) * 1000);
    this.currentBannerPlayTime = 0;
    this.bannerPlayTimeInterval = setInterval(() => {
      this.currentBannerPlayTime++;
    }, 1000);
  }

  stopLoop() {
    if (this.picHandler) {
      clearTimeout(this.picHandler);
      this.picHandler = null;
    }
  }

  async skipAd() {
    this.stopLoop();

    if (this.bannerPlayTimeInterval) {
      clearInterval(this.bannerPlayTimeInterval);
      this.bannerPlayTimeInterval = null;
    }

    const video = document.querySelector(`#video-${this.currentPictureIdx}`) as HTMLVideoElement;

    await this.$pos.analyticsManager.logAdEvent(
      "duration",
      this.currentAd._id,
      this.banners?.[this.currentPictureIdx].type === "image" ? this.currentBannerPlayTime : video.currentTime,
    );

    if (this.currentAdResolve) {
      const r = this.currentAdResolve;
      this.currentAdResolve = null;
      r();
    }
  }

  async showNextPicture() {
    let video = document.querySelector(`#video-${this.currentPictureIdx}`) as HTMLVideoElement;

    if (this.currentAd) {
      await this.$pos.analyticsManager.logAdEvent(
        "duration",
        this.currentAd._id,
        this.banners?.[this.currentPictureIdx].type === "image"
          ? this.banners?.[this.currentPictureIdx]?.duration
          : video.currentTime,
      );
    }
    if (this.bannerPlayTimeInterval) {
      clearInterval(this.bannerPlayTimeInterval);
      this.bannerPlayTimeInterval = null;
    }

    this.currentPictureIdx = this.currentPictureIdx + 1;
    const banner = this.banners?.[this.currentPictureIdx];
    if (!banner) {
      this.stopLoop();
      if (this.currentAdResolve) {
        const r = this.currentAdResolve;
        this.currentAdResolve = null;
        r();
      }
      return;
    }
    this.startLoop();
    if (banner.type === "video") {
      // Reload and restart video
      await this.$nextTick();
      video = document.querySelector(`#video-${this.currentPictureIdx}`) as HTMLVideoElement;
      if (video && video.src) {
        video.currentTime = 0;
        video.play();
      } else {
        this.showNextPicture();
      }
    }
  }

  @Watch("banners")
  onPictureChanged() {
    this.currentPictureIdx = 0;
    this.startLoop();
  }
}
