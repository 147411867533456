
import Dialog from "@feathers-client/mixins/Dialog";
import { Component, Prop, mixins } from "@feathers-client";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import _ from "lodash";

@Component
export default class OrderReview extends mixins(Dialog) {
  @Prop()
  order;

  @Prop()
  user

  rating: number = 0;

  async submit() {
    if (this.rating === 0) {
      this.$store.commit("SET_ERROR", this.$t("pos.review.ratingError"));
      return;
    }
    if (!this.order || !this.order._id) {
      this.$store.commit("SET_ERROR", this.$t("pos.review.orderError"));
      return;
    }
    try {
      let res = await this.$feathers.service("shop/order/ratings").create({
        order: this.order._id,
        rating: this.rating,
        user: this.user?._id,
      });
      console.log(res);
      this.modalResult()
    } catch (e) {
      this.$store.commit("SET_ERROR", e);
      console.log(e);
    }
  }

}
