
import Dialog from "@feathers-client/mixins/Dialog";
import { mixins, Component, Prop, Watch, FindType, FindPopRawType } from "@feathers-client";
import { ProductCardInfo } from "./ProductCard.vue";
import { Order } from "@common/pos";
import _ from "lodash";

@Component
export default class ProductInputCard extends mixins(Dialog) {
  @Prop()
  session: Order;

  variant = "amount";
  amount = "";
  productDetail = {
    id: 1,
    image: "https://nutritionsource.hsph.harvard.edu/wp-content/uploads/2018/08/bananas-1354785_1920-1024x683.jpg",
    tag: [],
    productTitle: "4011 香蕉",
    amount: 1,
    // amountUnit: "包",
    // subAmount: 2,
    // subAmountUnit: "件",
    // subAmountPrice: 3,
    sku: "9734028723",
    // originalPrice: 6,
    price: 3,
    amountSold: 10000,
    rating: 4,
    ratingNumber: 21,
  };

  productCode: string = "";
  selectingSku = null;
  productSkus = [];
  productComments: FindPopRawType<["user"], "shop/product/comments">[] = [];
  productCommentSummaries: FindType<"shop/product/comments/summary">[] = [];
  errorMessages: string = "";

  productGroups: FindType<"shop/product/searches">[] = [];

  get notValid() {
    if (this.items.length) {
      return !parseInt(this.amount, 10);
    }
    return this.productCode.length > 14 || this.productCode.length < 1;
  }

  get hint() {
    if (this.items.length) {
      switch (this.variant) {
        case "amount":
          return "kiosk.inputProductQuantity";
        case "weight":
        default:
          return "kiosk.inputProductWeight";
      }
    } else {
      return "kiosk.inputProductCode";
    }
  }

  get unit() {
    switch (this.variant) {
      case "amount":
        return "件";
      case "weight":
      default:
        return "g";
    }
  }

  get items() {
    return this.productSkus.map(it => ({
      ...it,
      image: it.mainImage || it.images?.[0],
      tag: this.productGroups[0]?.tags.map(it => ({ color: it.color, text: this.$td(it.name) })),
      sku: it._id,
      productTitle: this.$td(it.displayName || it.name),
      originalPrice: this.productGroups[0]?.originalPrice,
      price: this.productGroups[0]?.price,
      amountSold: this.productGroups[0]?.cachedSales,
      rating: this.productCommentSummaries[0]?.average,
      ratingNumber: this.productCommentSummaries[0]?.total,
      comments: this.productComments,
      linkedProduct: it,
    }));
  }

  clearAmount() {
    if (this.items.length) {
      this.amount = "";
    } else {
      this.productCode = "";
    }
  }

  openProductDialog(item) {
    this.$openDialog("hkpc-product-ad-detail-card", {
      productInfo: item,
    });
  }

  async confirm() {
    try {
      if (this.items.length) {
        _.forEach(this.productSkus, async el => {
          await this.session.addProduct(
            {
              sku: el._id,
            },
            {
              quantity: parseInt(this.amount, 10),
              moveToBottom: true,
            },
          );

          this.$pos.analyticsManager.logOrderEvent("addToCart", this.session._id, {
            sku: el,
            quantity: parseInt(this.amount, 10),
          });
        });
        this.modalResult(true);
      } else {
        if (!this.productCode) {
          throw new Error(`${this.$t("pos.error.inputProductCode")}`);
        }
        this.productSkus = await this.$feathers.service("shop/product/skus").find({
          query: {
            slug: this.productCode,
            $paginate: false,
          },
          paginate: false,
        });
        if (this.productSkus.length < 1) {
          throw new Error(`${this.$t("pos.error.wrongProductCode")}`);
        }
        this.errorMessages = "";
      }
    } catch (e) {
      this.errorMessages = e.message;
      console.error(e);
    }
  }

  @Watch("products")
  async fetchProductInfo() {
    try {
      this.productComments = await this.$feathers.service("shop/product/comments").find({
        query: {
          [this.session.viewingMode === "product" ? "product" : "sku"]: this.productSkus.map(el => el._id),
          $populate: [{ path: "user", model: "ShopUser" }],
          $sort: {
            createdAt: -1,
          },
          $paginate: false,
        },

        paginate: false,
      });
    } catch (e) {
      console.error(e);
    }
    try {
      this.productCommentSummaries = await Promise.all(
        this.productSkus.map(el => {
          return this.$feathers.service("shop/product/comments/summary").create({
            [this.session.viewingMode === "product" ? "product" : "sku"]: el._id,
            shop: `${this.$shop._id}`,
          });
        }),
      );
    } catch (e) {
      console.error(e);
    }

    try {
      this.productGroups = await Promise.all(
        this.productSkus.map(async el => {
          return (
            await this.$feathers
              .service("shop/product/searches")
              .find({ query: { productGroup: el.productGroup, $paginate: false }, paginate: false })
          )?.[0];
        }),
      );
    } catch (e) {
      console.error(e);
    }
  }
}
